.entry-meta-field {
  &.entry-meta-author {
    display: inline-block;
  }
}


  .date1 {
    font-size: 0.8rem;
    color: $dark-gray;
    
  }

.headtxt{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: "Independent Sans";
    
}

.imgComment{
    margin-top: .5rem;
    margin-bottom: .5rem;
    
}
 
.block-container > .mpu-width {
  width: 300px;
  height: 250px;
}



.imgComment >p {
border-right: solid;
    border-right-width: thin;
        white-space: break-spaces;
            padding-right: 0.5rem;
        
}

.photo_gallery figcaption{
    margin-top: .5rem;
    margin-bottom: .5rem;
    
}
 

.photo_gallery figcaption{
border-right: solid;
    border-right-width: thin;
        white-space: break-spaces;
            padding-right: 0.5rem;
        
}


body.urdu-theme{
  font-size: 20px;
}