#main-footer {
    margin: 2rem 1rem;
    padding: 2rem 1.25rem;
    border-top: 1px solid #dadfe1;
    color: $secondary-color;
    @include breakpoint(large) {
        padding-#{$default-direction}: 40px;
        padding-#{$opposite-direction}: 40px;
    }

    .eng-theme &,
    .turkish-theme & {
        font-size: $font-small3;
    }

    h6 {
        font-family: $font-secondary;
        font-weight: 500;
        font-size: inherit;
        text-transform: uppercase;

        [dir="rtl"] & {
            font-weight: 800;
        }
    }

    ul.menu.vertical {
        font-family: $font-secondary;
        font-weight: 300;
        list-style: none;
        margin-#{$default-direction}: 0;
        margin-bottom: 1rem;

        [dir="rtl"] & {
            font-weight: 400;
        }

        a {
            display: block;
            color: $secondary-color;
            padding: 6px 0;
            line-height: 1.1;

            &:hover {
                color: $primary-color;
            }
        }
    }
}

.main-footer-before {
    margin-bottom: 1.5rem;
}

.main-footer-inner {
    > ul {
        list-style: none;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media (min-width: 1000px) {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
        }

        > li {
            width: 100%;
            margin-bottom: 20px;

            &:nth-of-type(4) {
                order: 5;
            }

            @media (min-width: 768px) {
                grid-row: span 2;
                width: auto;
            }
            @media (min-width: 768px) {
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    grid-row: span 1;
                    -ms-grid-row-span: 1;
                }
                &:nth-of-type(3) {
                    order: 5;
                }
                &:nth-of-type(4) {
                    order: 0;
                }
            }
            @media (min-width: 1000px) {
                width: 50%;
                margin-bottom: 20px;
                flex: 1 0 145px;
                &:nth-of-type(3) {
                    order: 0;
                }
            }

            &:first-of-type {
                grid-row: span 2;
                -ms-grid-row-span: 2;
            }
        }
    }
}

.main-footer-after {
    padding: 0.5rem 0;
    line-height: 1.5;
}

.logo-footer {
    img {
        height: 80px;
    }

    li & {
        margin-bottom: 1.5rem;
    }
}

.footer-links {
    @include breakpoint(small only) {
        padding-top: 2rem;
    }
    margin-#{$default-direction}: 0;
    margin-bottom: 2rem;
    margin-top: -7px;
    list-style: none;
    clear: $default-direction;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;

    a {
        font-size: $font-small3;
        line-height: 32px;
        display: block;
        color: $secondary-color;

        &:hover {
            color: $primary-color;
        }
    }

    @media only screen and (min-width: 450px) {
        -webkit-columns: 3;
        -moz-columns: 3;
        columns: 3;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }
    @include breakpoint(medium) {
        clear: none;
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2;
    }
    @include breakpoint(large) {
        float: $default-direction;
        width: auto;
        clear: none;
        -webkit-columns: 3;
        -moz-columns: 3;
        columns: 3;
        -webkit-column-gap: 40px;
        -moz-column-gap: 40px;
        column-gap: 40px;
    }
}

.footer-menu {
    @include breakpoint(small only) {
        padding-top: 1rem;
    }

    a {
        font-size: $font-small3;
        line-height: 1.2;
        display: block;
        color: $secondary-color;

        &:hover {
            color: $primary-color;
        }
    }
}

.copyright {
    text-align: $default-direction;
    padding: 0 1rem;
    font-size: $font-small2;
    color: $medium-gray;
}

.footer-extra-links {
    font-size: $font-small1;

    a {
        padding: 0 0.75rem;
        color: $white;
        border-#{$opposite-direction}: 1px solid $white;

        &:hover {
            text-decoration: underline;
        }
    }
}

// #main-footer {
//     margin: 2rem 0 0;
//     padding: 24px;
//     color: $secondary-color;
//     background: black;
//     color: white;

//     [dir="rtl"] & {
//         @include breakpoint(medium down) {
//             padding-bottom: 98px;
//         }
//     }

//     .eng-theme &,
//     .turkish-theme & {
//         font-size: $font-small3;
//     }

//     h6 {
//         font-family: $font-secondary;
//         font-weight: 500;
//         font-size: inherit;
//         text-transform: uppercase;

//         [dir="rtl"] & {
//             font-weight: 400;
//             font-size: 14px;
//             margin-bottom: 16px;
//         }
//     }

//     ul.menu.vertical {
//         font-family: $font-secondary;
//         font-weight: 300;
//         list-style: none;
//         margin-#{$default-direction}: 0;
//         margin-bottom: 1rem;

//         [dir="rtl"] & {
//             font-weight: 400;
//         }

//         a {
//             display: block;
//             color: $white;
//             padding: 6px 0;
//             line-height: 1.1;

//             &:hover {
//                 color: $primary-color;
//             }
//         }
//     }

//     [dir="rtl"] & {
//         .social-btn {
//             width: 30px;
//             height: 30px;
//             i {
//                 font-size: 14px;
//             }
//         }
//     }
// }

// .main-footer-before {
//     margin-bottom: 1.5rem;
// }

// .main-footer-inner {
//     &__grid {
//         > .cell:not(:first-child):not(:last-child) {
//             [dir="rtl"] & {
//                 @include breakpoint(large) {
//                     border-#{$opposite-direction}: 0.5px solid #e7e7e7;
//                 }
//             }
//         }
//     }
//     > ul {
//         list-style: none;
//         margin: 0;
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         width: 100%;
//         @media (min-width: 768px) {
//             grid-template-columns: 1fr 1fr 1fr 1fr;
//         }
//         @media (min-width: 1000px) {
//             align-items: flex-start;
//             display: flex;
//             flex-wrap: wrap;
//         }

//         > li {
//             width: 100%;
//             margin-bottom: 20px;

//             &:nth-of-type(4) {
//                 order: 5;
//             }

//             @media (min-width: 768px) {
//                 grid-row: span 2;
//                 width: auto;
//             }
//             @media (min-width: 768px) {
//                 &:nth-of-type(2),
//                 &:nth-of-type(3) {
//                     grid-row: span 1;
//                     -ms-grid-row-span: 1;
//                 }
//                 &:nth-of-type(3) {
//                     order: 5;
//                 }
//                 &:nth-of-type(4) {
//                     order: 0;
//                 }
//             }
//             @media (min-width: 1000px) {
//                 width: 50%;
//                 margin-bottom: 20px;
//                 flex: 1 0 145px;
//                 &:nth-of-type(3) {
//                     order: 0;
//                 }
//             }

//             &:first-of-type {
//                 grid-row: span 2;
//                 -ms-grid-row-span: 2;
//             }
//         }
//     }
// }

// .main-footer-after {
//     padding: 0.5rem 0;
//     line-height: 1.5;
// }

// .logo-footer {
//     @include breakpoint(medium down) {
//         margin-bottom: 16px;
//     }
//     img {
//         height: 80px;
//     }

//     li & {
//         margin-bottom: 1.5rem;
//     }
// }

// .footer-links {
//     @include breakpoint(medium down) {
//         padding-top: 2rem;
//     }
//     margin-#{$default-direction}: 0;
//     margin-bottom: 2rem;
//     margin-top: -7px;
//     list-style: none;
//     clear: $default-direction;
//     -webkit-columns: 2;
//     -moz-columns: 2;
//     columns: 2;
//     -webkit-column-gap: 0;
//     -moz-column-gap: 0;
//     column-gap: 0;

//     a {
//         font-size: $font-small3;
//         line-height: 32px;
//         display: block;
//         color: $secondary-color;

//         &:hover {
//             color: $primary-color;
//         }
//     }

//     @media only screen and (min-width: 450px) {
//         -webkit-columns: 3;
//         -moz-columns: 3;
//         columns: 3;
//         -webkit-column-gap: 20px;
//         -moz-column-gap: 20px;
//         column-gap: 20px;
//     }
//     @include breakpoint(medium) {
//         clear: none;
//         -webkit-columns: 2;
//         -moz-columns: 2;
//         columns: 2;
//     }
//     @include breakpoint(large) {
//         float: $default-direction;
//         width: auto;
//         clear: none;
//         -webkit-columns: 3;
//         -moz-columns: 3;
//         columns: 3;
//         -webkit-column-gap: 40px;
//         -moz-column-gap: 40px;
//         column-gap: 40px;
//     }
// }

// .footer-menu {
//     display: flex;
//     flex-direction: column;
//     gap: 8px;
//     position: relative;
//     @include breakpoint(medium down) {
//         margin-top: 36px;
//         padding-top: 12px;
//         [dir="rtl"] & {
//             gap: 16px;
//         }
//     }
//     &:before {
//         @include breakpoint(medium down) {
//             content: "";
//             background-color: #e7e7e7;
//             height: 0.5px;
//             width: 50%;
//             position: absolute;
//             top: 0;
//         }
//     }
//     a {
//         font-size: $font-small3;
//         line-height: 22px;
//         display: block;
//         color: $white;

//         [dir="rtl"] & {
//             @include breakpoint(medium down) {
//                 padding-top: 0;
//                 padding-bottom: 0;
//             }
//         }
//         &:hover {
//             color: $primary-color;
//         }
//     }
// }

// .copyright {
//     text-align: center;
//     padding: 0 1rem;
//     font-size: 14px;
//     color: $medium-gray;
//     margin-top: 40px;
//     @include breakpoint(medium down) {
//         text-align: right;
//     }
//     @include breakpoint(large) {
//         margin-top: 89px;
//     }
// }

// .footer-extra-links {
//     font-size: $font-small1;

//     a {
//         padding: 0 0.75rem;
//         color: $white;
//         border-#{$opposite-direction}: 1px solid $white;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// }
