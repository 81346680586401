@media only screen {
  .no-spacer {
    margin: 0 !important;
  }
  .no-top-spacer {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .no-bottom-spacer {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .no-left-spacer {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .no-right-spacer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .top-spacer--xl {
    margin-top: 3rem !important;
  }
  .top-spacer--l {
    margin-top: 2.5rem !important;
  }
  .top-spacer--m {
    margin-top: 2rem !important;
  }
  .top-spacer {
    margin-top: 1.5rem !important;
  }
  .top-spacer--s {
    margin-top: 1rem !important;
  }
  .top-spacer--xs {
    margin-top: 0.5rem !important;
  }
  .bottom-spacer--xl {
    margin-bottom: 3rem !important;
  }
  .bottom-spacer--l {
    margin-bottom: 2.5rem !important;
  }
  .bottom-spacer--m {
    margin-bottom: 2rem !important;
  }
  .bottom-spacer {
    margin-bottom: 1.5rem !important;
  }
  .bottom-spacer--s {
    //margin-bottom: 0.75rem !important;
    margin-bottom: 16px !important;
  }
  .bottom-spacer--xs {
    margin-bottom: 0.5rem !important;
  }
}

@include -zf-each-breakpoint {
  @for $i from 1 through $block-grid-max {
    .#{$-zf-size}-up-#{$i} {
      .column:last-child,
      .columns:last-child {
        @include grid-column-end;
      }
    }
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 69px;
  @include breakpoint(large) {
    padding-top: 115px;
    [dir="rtl"] & {
      padding-top: 122px;
    }
  }

  &.persian-simple {
    padding: 0;
  }
}

.card.card-item.card-podcast {
  height: calc(100% - 2rem);
}

.gutters-1x {
  @include xy-gutters(20, "margin", $negative: true);

  > .cell {
    @include xy-gutters(20, "padding", $negative: false);
  }
}

.gutters-2x {
  @include xy-gutters(40, "margin", $negative: true);

  > .cell {
    @include xy-gutters(40, "padding", $negative: false);
  }
}

.gutters-margin-1x {
  @include xy-gutters(20, "margin", $negative: true);

  .cell {
    @include xy-gutters(20, "margin", $negative: false);
  }
}

.gutters-margin-2x {
  //@include xy-gutters(40, 'margin', $negative: true);
  .cell {
    //@include xy-gutters(40, 'margin', $negative: false);
    @include xy-gutters(40, margin, right left, false);
  }
}

/*@include breakpoint(large) {
  [dir="rtl"] {
    .large-uncentered, .large-push-0, .large-pull-0 {
      float: $opposite-direction;
      margin-#{$default-direction}: 0;
      margin-#{$opposite-direction}: 0;
      position: static;
    }
  }
}*/

.vertical-center {
  @include vertical-center();
}

.center-element {
  position: absolute;
  #{$default-direction}: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.round {
  border-radius: 1000px;
}

.use-loader {
  height: 360px;
  overflow: hidden;
  background: url(../img/ajax-loader.gif) no-repeat center center transparent;

  > div {
    opacity: 0;
    visibility: hidden;
    height: 400px;
  }
}

.use-loader.slick-initialized.slick-loaded {
  background: none;
  height: auto;
  overflow: visible;

  > div {
    height: auto;
  }

  &.slider-nav {
    background: $black;
  }
}

.use-loader.slick-initialized.slick-loaded .slick-list,
.use-loader.slick-initialized.slick-loaded button {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease-in;
}

.round {
  border-radius: 1000px;
  overflow: hidden;
}

/*
.input-group.with-radius > *:first-child {
  border-radius: 0 5px 5px 0;
}

.input-group.with-radius > *:last-child > * {
  border-radius: 5px 0 0 5px;
}
*/

.show-for-printable-area {
  display: none;
}

input {
  &.radius,
  .input-group > &:first-child {
    border-radius: 4px;
  }

  .search-form & {
    &.input-group-field {
      border-radius: 4px !important;
    }
  }
}

textarea {
  height: 10rem;
}

.arial-heading {
  font-family: Arial, sans-serif;
}

.font-primary {
  font-family: $font-primary;
}

.font-secondary {
  font-family: $font-secondary;
}

a {
  transition:
    color 0.25s ease 0s,
    background-color 0.25s ease 0s,
    opacity 0.25s ease 0s;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;

  [dir="rtl"] & {
    line-height: 1.4;
  }

  .urdu-theme & {
    line-height: 1.2;
  }
}

/*
.off-canvas {
  height: 100%;
}

.js-off-canvas-exit {
  z-index: 10;
}
*/

/*[dir="rtl"] .close-button {
  #{$opposite-direction}: auto;
  #{$default-direction}: 1rem;
}*/

/*.placeholder {
  font-size: 0.875rem;
  color: #aaa;
}*/

p {
  font-size: $paragraph-font-size;
}

figure {
  margin: 0 0 0.5rem;

  &.round {
    overflow: hidden;
  }
}

.leaderboard-ad {
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
}

button,
.button {
  &.radius {
    border-radius: 5px;
  }

  &.secondary {
    &:hover {
      background-color: $primary-color;
    }
  }

  &.gray {
    background-color: $gainsboro;
    color: $black;

    &:hover {
      background-color: $secondary-color;
      color: $white;
    }
  }
}

/* ------ navigation, panel and sticky header ------ START ------ */

#sticky-header {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: -100px;
  width: 100%;
  z-index: 100;
  transition: all 0.4s ease 0s;
  background-color: $white;

  &.stuck-header {
    visibility: visible;
    opacity: 1;
    top: 0;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
    box-shadow: 0 1px 10px 0 $gray;
    /*~ .canvas-panel {
      top: 40px;
    }*/
  }
}

.sticky-header-spacer {
  display: none;
  height: auto;
  visibility: hidden;
}

.toggle-nav-panel {
  position: relative;
  z-index: 1;
  font-size: 1.75rem;
  cursor: pointer;
  color: $secondary-color;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  height: 100%;
  padding: 0 1.5rem;

  &:hover {
    color: $primary-color;
  }

  &.is-active {
  }
}

/* ------ navigation, panel and sticky header ------ END ------ */

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

main,
header,
footer {
  position: relative;
}

main {
  margin: 0 auto 3rem;

  .front & {
  }

  .persian-simple & {
    padding: 0;
  }

  @include breakpoint(small only) {
    .grid-container {
      padding-#{$opposite-direction}: 1rem;
      padding-#{$default-direction}: 1rem;
    }
  }
  @include breakpoint(medium only) {
    .grid-container {
      padding-#{$opposite-direction}: 0.75rem;
      padding-#{$default-direction}: 0.75rem;
      max-width: 768px;
    }
  }
}

.mobile-webview {
  padding: 0;

  [dir="rtl"] & {
    padding: 0;
  }

  main {
    padding: 0;
    margin: 0;

    [dir="rtl"] & {
      padding: 0;
      margin: 0;
    }
  }

  .entry-header.has-avatar .entry-title h1,
  .entry-meta-field.entry-meta-author > a {
    font-style: normal;
  }
}

.static-column-wrap {
  @include breakpoint(large) {
    width: 640px;
  }
}

.content-wrap,
.static-column-wrap {
  .equalise-columns & {
    margin-bottom: 1rem;
    @include breakpoint(large) {
      margin-bottom: 0;
      height: 100%;
    }
  }
}

header#main-header {
  background-color: $white;
  position: fixed;
  z-index: 5000000;
  top: 0;
  #{$default-direction}: 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.dropdown-toggle {
  vertical-align: middle;
  cursor: pointer;
  line-height: 36px;
  height: 100%;
  font-weight: 500;
  padding: 0 22px;

  &:hover {
    color: $primary-color;
  }
}

.dropdown-menu {
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  #{$opposite-direction}: 0;
  width: 178px;
  background-color: $white;
  border-color: rgba(218, 223, 225, 0.5);
  border-style: solid;
  border-width: 1px 0 1px 1px;

  [dir="rtl"] & {
    border-width: 1px 1px 1px 0;
  }

  li {
    line-height: 1;

    &:hover {
      &:after {
        content: " ";
        width: 5px;
        position: absolute;
        bottom: 0;
        #{$opposite-direction}: 0;
        height: 100%;
        background-color: $primary-color;
        display: none;
      }
    }

    &:last-child {
      font-family: Tahoma;
      //text-align: $opposite-direction;
      font-size: $font-medium1;
    }

    a {
      color: $secondary-color;
      font-size: $font-medium1;
      display: block;
      padding: 16px 0 16px 24px;

      [dir="rtl"] & {
        padding: 16px 24px 16px 0;
      }

      i {
        font-size: 1.25rem;
        margin-#{$opposite-direction}: 1rem;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }

  &.open {
    display: block;
  }
}

.check-icon {
  padding: 0 0.25rem;
}

.search-form {
  background: rgba(0, 0, 0, 0.8);
  display: none;
  clear: both;
  padding: 15px 25px 15px 18px;
  position: fixed;
  width: 90%;
  z-index: 1;
  #{$opposite-direction}: 0;

  > div {
    position: relative;
  }

  &.open {
    display: block;
  }

  @include breakpoint(medium) {
    width: 50%;
  }

  .search-form-inner {
    position: relative;
  }

  .form-field,
  .form-item.form-type-textfield {
    margin: 0;
    padding: 0;

    input {
      width: 100%;
      border: 0;
      line-height: 1;
      padding: 6px;
      height: 1.75rem;
      font-size: $font-small3;
    }
  }

  .button,
  input[type="submit"] {
    font-family: "icomoon";
    font-size: 0.75rem;
    border: none;
    background-color: $primary-color;
    color: $white;
    position: absolute;
    #{$opposite-direction}: 0;
    top: -1px;
    display: block;
    outline: 0;
    bottom: 0;
    line-height: 1;
  }
}

.main-header-before {
  border-bottom: 1px solid rgba(218, 223, 225, 0.7);
}

.mag-meta {
  padding-top: 0.5rem;
}

.subheader-block1 {
  height: 100%;

  > ul {
    font-family: $font-secondary;
    height: 100%;

    .eng-theme &,
    .turkish-theme & {
      font-size: $font-small2;
    }

    > li {
      position: relative;
      // border-#{$opposite-direction}: 1px solid rgba(218, 223, 225, .7);

      &:last-child {
        border: 0;
      }

      &:after {
        content: " ";
        height: 2px;
        position: absolute;
        bottom: 0;
        #{$default-direction}: 0;
        width: 100%;
        background-color: #ec1a2e;
        display: none;
      }

      &:hover {
        &:after {
          display: block;
        }
      }

      @include breakpoint(small down) {
        &.account,
        &.edition {
          display: none;
        }
      }

      > a {
        text-transform: uppercase;
        display: block;
        padding: 0;
        height: 100%;

        .button {
          padding: 0;
        }
      }

      &.subscription {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        @include breakpoint(630px down) {
          display: none;
        }

        &:after {
          display: none;
        }

        a {
          height: auto;
          color: $secondary-color;
          padding: 0 1.25rem 0 0;

          [dir="rtl"] & {
            padding: 0 0 0 1.25rem;
          }

          &:hover {
            color: $primary-color;
          }

          strong {
            padding-#{$default-direction}: 6px;
          }
        }
      }

      &.quick-search {
        .main-header-before & {
          @include breakpoint(large) {
            display: none;
          }
          /*@include breakpoint(small only) {
            display: none;
          }*/
        }

        i {
          vertical-align: middle;
          font-size: 1.25rem;
        }
      }

      &.edition {
        button {
          padding: 0 1.625rem;
        }

        i {
          vertical-align: middle;
          font-size: 1.5rem;
        }
      }

      &.account {
        i {
          vertical-align: middle;
          font-size: 1.5rem;
        }
      }
    }
  }
}

.subheader-block {
  height: 100%;

  > ul {
    font-family: $font-secondary;
    height: 100%;

    .eng-theme &,
    .turkish-theme & {
      font-size: $font-small2;
    }

    > li {
      position: relative;
      border-#{$opposite-direction}: 1px solid rgba(218, 223, 225, 0.7);

      &:last-child {
        border: 0;
      }

      &:after {
        content: " ";
        height: 2px;
        position: absolute;
        bottom: 0;
        #{$default-direction}: 0;
        width: 100%;
        background-color: #ec1a2e;
        display: none;
      }

      &:hover {
        &:after {
          display: block;
        }
      }

      @include breakpoint(small down) {
        &.account,
        &.edition {
          display: none;
        }
      }

      > a {
        text-transform: uppercase;
        display: block;
        padding: 0;
        height: 100%;

        .button {
          padding: 0;
        }
      }

      &.subscription {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        @include breakpoint(630px down) {
          display: none;
        }

        &:after {
          display: none;
        }

        a {
          height: auto;
          color: $secondary-color;
          padding: 0 1.25rem 0 0;

          [dir="rtl"] & {
            padding: 0 0 0 1.25rem;
          }

          &:hover {
            color: $primary-color;
          }

          strong {
            padding-#{$default-direction}: 6px;
          }
        }
      }

      &.quick-search {
        .main-header-before & {
          @include breakpoint(large) {
            display: none;
          }
          /*@include breakpoint(small only) {
            display: none;
          }*/
        }

        i {
          vertical-align: middle;
          font-size: 1.25rem;
        }
      }

      &.edition {
        button {
          padding: 0 1.625rem;
        }

        i {
          vertical-align: middle;
          font-size: 1.5rem;
        }
      }

      &.account {
        i {
          vertical-align: middle;
          font-size: 1.5rem;
        }
      }
    }
  }
}

.logo {
  margin: 12px 0;
  padding-#{$default-direction}: 16px;

  img {
    height: 27px;

    [dir="rtl"] &,
    .turkish-theme & {
      height: 44px;
    }
  }

  @include breakpoint(large) {
    img {
      height: 34px;

      [dir="rtl"] &,
      .turkish-theme & {
        height: 54px;
      }
    }
  }
}

.logo-txt {
  @include breakpoint(350) {
    margin-#{$default-direction}: 0.25rem;
  }
}

.holder-area {
  position: relative;

  header &,
  .subheader-block & {
    height: 30px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
  }
}

.current-time,
.current-issue-number {
  font-family: Arial;
  font-size: $font-small3;
}

.entry-comments {
  margin-bottom: 2rem;

  .box-title {
    margin-bottom: 0.5rem;

    h3,
    h4 {
      color: $secondary-color;
    }
  }

  a {
    color: $black;

    &:hover {
      color: $primary-color !important;
    }
  }
}

.comments-container {
  background-color: $smoke;
  border: 2px solid $gray;
  padding: 0 0.5rem 0.5rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.comments-head {
  text-align: center;
  position: relative;
  margin-top: -22px !important;
  margin-bottom: 1rem;

  a {
    display: block;
  }

  i {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1.5rem;
    color: $white;
    background: $primary-color;
    border-radius: 50%;
  }

  h4,
  h5 {
    color: $black;
    margin: 1rem 0 0;
  }
}

.comments-inner {
  display: none;
  background: $white-smoke;
  border: 1px solid $light-gray;
  padding: 0.9375rem;
  color: #707070;

  .subheader {
    margin-bottom: 1.25rem;
  }

  button {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0;
  }
}

.custom-icon-minus {
  &:before {
    content: "-";
    font-family: verdana;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
  }
}

.grid-items {
  &.style-v2 {
    .article-item {
      padding: 1rem 0;
      border-top: 1px solid $light-gray;
      border-bottom: 1px dashed #bfbfbf;
    }
  }

  &.has-bottom-border {
    .article-item {
      padding-bottom: 0.5rem;
      border-bottom: 2px solid $secondary-color;
    }
  }
}

.grid-items-wrapper {
  margin-bottom: 1rem;
}

.fill-content {
  display: block;
  height: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 0;
  margin: auto;
  object-fit: cover;

  &.abs-element {
    position: absolute;
    top: 0;
    #{$default-direction}: 0;
    bottom: 0;
    #{$opposite-direction}: 0;
  }

  .article-item-img &,
  .entry-media-inner-img & {
    position: absolute;
    top: 0;
    #{$default-direction}: 0;
    bottom: 0;
    #{$opposite-direction}: 0;
  }
}

.sizer {
  display: block;
  padding-top: 66.6667%;

  .entry-media & {
    padding-top: 75%;
  }

  .with-square-sizer & {
    padding-top: 100%;
  }

  .node-type-cartoons &,
  .page-cartoons &,
  .article-block-cartoon & {
    padding-top: 72%;
  }

  /*.entry-media-inner-img & {
    padding-top: 40%;
  }*/
}

.article-item {
  position: relative;

  &.article-hero {
    height: 100%;
    // margin-bottom: 0.5rem;

    .block-wrapper & {
      margin-bottom: 0.5rem;
    }

    @include breakpoint(medium) {
      margin-bottom: -6px;
      .article-item-img {
        height: 100%;
      }
    }

    .article-item-content {
      > :not(.category-field) {
        a {
          display: block;
        }
      }
    }

    &.media-video,
    &.media-audio {
      .abs-bottom-element {
        padding-top: 24px;
        @include breakpoint(small only) {
          // margin-top: -80px;
        }

        &:before {
          background: rgba(236, 26, 46, 0.8);
          border-radius: 50%;
          cursor: pointer;
          text-align: center;
          content: "\e90b";
          position: absolute;
          font-family: "icomoon";
          width: 32px;
          height: 32px;
          line-height: 32px;
          font-size: 1.25rem;
          z-index: 1;
          top: -16px;
          text-indent: 3px;

          [dir="rtl"] & {
            text-indent: -3px;
          }

          @include breakpoint(medium) {
            .splash-row > .grid-x > .cell.large-8 > & {
              text-indent: 6px;
              font-size: 2rem;
              top: -28px;
              margin: 0;
              width: 56px;
              height: 56px;
              line-height: 56px;

              [dir="rtl"] & {
                text-indent: -6px;
              }
            }
          }
        }
      }
    }

    &.media-audio {
      .abs-bottom-element {
        &:before {
          content: "\e91e";
        }
      }
    }

    .splash-row & {
      @include breakpoint(small only) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .articles-list & {
    &.article-hero {
      margin-bottom: 0;

      .article-item-img {
        @include breakpoint(large) {
          height: 260px;
        }
      }
    }
  }

  &.video-grid-story {
    &.img-as-bg {
      @include breakpoint(medium down) {
        .article-item-img {
          position: relative;
          padding-top: 56.25%;
        }
      }
      @include breakpoint(large) {
        height: 360px;
      }
    }
  }

  &.full-grid-story {
    &.related-content {
      @include breakpoint(medium down) {
        h2 {
          font-size: $font-large2;
        }
      }
    }

    .article-item-category {
      margin-top: -27px;
      margin-bottom: 1rem;
    }

    &.img-as-bg {
      @include breakpoint(small only) {
        .article-item-img {
          position: relative;
          padding-top: 56.25%;
        }
      }
      @include breakpoint(medium only) {
        @include xy-grid(vertical, nowrap);
        .article-item-img {
          @include xy-cell(auto, false);
        }
        .article-item-content {
          @include xy-cell(auto, false);
        }
      }
      @include breakpoint(medium) {
        height: 600px;
      }
    }
  }

  &.half-grid-story {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $light-gray;

    &.img-as-bg {
      @include breakpoint(small only) {
        .article-item-img {
          position: relative;
          padding-top: 56.25%;
        }
      }
      @include breakpoint(medium) {
        height: 290px;
      }

      .grid-items.medium-up-3 & {
        @include breakpoint(medium only) {
          height: 230px;
        }
      }
    }
  }

  &.small-grid-story {
    &.img-as-bg {
      @include breakpoint(medium) {
        height: 230px;
      }
    }
  }

  &.card-grid-story {
    &.img-as-bg {
      @include breakpoint(medium) {
        height: 600px;
      }
    }

    .article-item-content {
      padding: 0.5rem 1.25rem;
      border: 1px solid #dadfe1;
    }
  }

  &.half-card-grid-story {
    margin-bottom: 0;
    height: 100%;
    border: 1px solid #d6d6d6;

    &.img-as-bg {
      .article-item-img {
        position: relative;
        padding-top: 56.25%;
      }
    }

    .article-item-content {
      padding: 0.5rem 0.75rem 1rem;
      //border: 1px solid #dadfe1;
    }
  }

  &.small-grid-story,
  &.half-grid-story,
  &.card-grid-story,
  &.half-card-grid-story {
    .article-item-category {
      margin-top: -19px;
    }
  }

  .grid-items & {
    max-width: 360px;
    margin-#{$default-direction}: auto;
    margin-#{$opposite-direction}: auto;
    margin-bottom: 2rem;
  }

  .duration {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    font-size: $font-xsmall;
    background-color: #000000;
    padding: 4px 4px 3px;
    line-height: 1;
  }

  &.article-video-hero .article-item-img {
    &:before {
      background: rgba(236, 26, 46, 0.8);
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      content: "\e90b";
      position: absolute;
      font-family: "icomoon";
      width: 50px;
      height: 50px;
      line-height: 48px;
      font-size: 2rem;
      z-index: 1;
      text-indent: 3px;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      margin: 0 auto;

      [dir="rtl"] & {
        text-indent: -3px;
      }
    }
  }

  &.article-video .article-item-img {
    &:before {
      background: rgba(236, 26, 46, 0.8);
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      content: "\e90b";
      position: absolute;
      font-family: "icomoon";
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 1.25rem;
      z-index: 1;
      text-indent: 3px;
      bottom: 0.5rem;
      #{$default-direction}: 0.5rem;

      [dir="rtl"] & {
        text-indent: -3px;
      }
    }
  }
}

.half-card-grid-stories {
  .cell {
    margin-bottom: 2rem;
  }
}

.article-item-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  #{$default-direction}: 0;
  #{$opposite-direction}: 0;
}

.img-as-bg {
  .article-item-img {
    background-color: $black;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: block;
    position: relative;
    height: 100%;

    > a {
      position: absolute;
      top: 0;
      bottom: 0;
      #{$default-direction}: 0;
      #{$opposite-direction}: 0;
      z-index: 1;
    }
  }
}

.article-item-img {
  position: relative;

  .horoscopes-articles & {
    margin-bottom: 0.5rem;
  }

  .card & {
    margin-bottom: 0;
  }

  a {
    &:after {
      content: "";
      display: block;
      position: absolute;
      #{$default-direction}: 0;
      #{$opposite-direction}: 0;
      top: 0;
      bottom: 0;
      background-color: transparent;
      transition:
        color 0.25s ease 0s,
        background-color 0.25s ease 0s,
        opacity 0.25s ease 0s;
    }

    &:hover:after,
    &.hovered-img:after {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.article-item-content {
  padding-top: 0.5rem;

  [dir="rtl"] & {
    //padding-top: 0.3rem;
  }
}

.article-item-title {
  a {
    color: $secondary-color;

    &:hover {
      color: $primary-color;
    }
  }

  .video-grid-story & {
    h2 {
      @include breakpoint(large) {
        font-size: 2.5rem;
      }
    }
  }

  .playlist & {
    h6 {
      font-size: $font-small3;
      font-weight: 600;
    }
  }

  .half-card-grid-story & {
    .article-item-content h4 {
      font-size: rem-calc(20);
    }
  }

  .object-item & {
    h6 {
      margin-bottom: 0;

      .eng-theme &,
      .turkish-theme & {
        font-size: $font-small4;
      }

      letter-spacing: 0.22px;
      font-weight: 500;

      [dir="rtl"] & {
        font-weight: 800;
      }

      @include breakpoint(medium) {
        .eng-theme .splash-row &,
        .turkish-theme .splash-row & {
          font-size: $font-small3;
        }
      }
    }
  }

  .block-wrapper & {
    h6 {
      font-weight: 300;
    }
  }

  .media-prefix {
    color: $primary-color;
    margin-#{$opposite-direction}: 0.4rem;
  }
}

.article-item-author {
  margin-top: 8px;
  font-size: $font-small3;
  font-weight: 700;
  font-style: italic;
  color: $primary-color;

  .urdu-theme & {
    font-style: normal;
  }

  .articles-list.cards & {
    @include breakpoint(medium down) {
      display: none;
    }
  }
}
.urdu-theme {
  p {
    font-size: 1.5rem;
  }
}
.article-item-author1 {
  margin-top: 0.3rem;
  font-size: $font-small3;
  font-weight: 700;
  font-style: italic;
  color: #337e81;

  .urdu-theme & {
    font-style: normal;
  }

  .articles-list.cards & {
    @include breakpoint(medium down) {
      display: none;
    }
  }
}

.article-item-category {
  position: relative;
  font-size: $font-small1;
  font-weight: 600;
  margin-bottom: 0.5rem;
  z-index: 1;

  a {
    line-height: 16px;
    display: inline-block;
    padding: 2px 8px;
    text-transform: uppercase;
    background-color: $primary-color;
    color: $white;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.article-item-labels {
  position: absolute;
  top: 0;
  height: auto;
  z-index: 1;
  font-family: $font-secondary;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: $white;
  letter-spacing: 0.26px;
  line-height: normal;
  text-shadow: none;
  display: flex;
  font-size: $font-xxsmall;

  .eng-theme &,
  .turkish-theme & {
    font-size: $font-xsmall;
  }

  a {
    background: $primary-color;
    padding: 4px 8px 2px;
    color: $white;
    display: flex;

    &:hover {
      background-color: $white;
      color: $primary-color;
    }
  }
}

.live-blog {
  background: #fffd38;
  color: #000;
  padding: 4px 8px 2px;
  display: inline-block;
}

.lead {
  padding-top: 16px;

  p {
    font-family: $font-secondary;

    .eng-theme &,
    .turkish-theme & {
      font-size: $font-small3;
    }

    margin-bottom: 0;
    @include breakpoint(large) {
      .article-hero .abs-bottom-element & {
        .eng-theme &,
        .turkish-theme & {
          font-size: $font-medium1;
        }
      }
    }
  }
}

.abs-bottom-element {
  //position: relative;
  background: rgba(0, 0, 0, 0.7);
  color: $white;
  display: block;
  width: 100%;
  margin: 0;

  padding: 16px;
  position: absolute;
  bottom: 0;
  #{$default-direction}: 0;

  a {
    position: relative;
    z-index: 1;
    color: $white;

    &:hover {
      color: $primary-color;
    }
  }

  @include breakpoint(small only) {
    .splash-row & {
      position: relative;
    }
  }

  .article-item-title {
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  .video-grid-story & {
    padding: 1.5rem;
    @include breakpoint(large) {
      padding-top: 80px;
    }
  }
}
.abs-bottom-element1 {
  padding: 0.75rem;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  color: $white;
  display: block;
  width: 100%;
  margin: 0;
  top: 0rem;
  backdrop-filter: blur(7px);

  a {
    position: relative;
    z-index: 1;
    color: $white;

    &:hover {
      color: $primary-color;
    }
  }

  @include breakpoint(medium) {
    padding: 1.5rem;
    position: absolute;
    bottom: 0;
    #{$default-direction}: 0;
  }

  .article-item-title {
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  .video-grid-story & {
    padding: 1.5rem;
    @include breakpoint(large) {
      padding-top: 80px;
    }
  }
}

.play-icon {
  font-size: 1.25rem;
  padding: 8px 6px 8px 10px;
  background: rgba(236, 26, 46, 0.8);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  #{$default-direction}: 10px;
  position: absolute;
  top: 10px;
  line-height: 1;
  text-align: center;
  @include breakpoint(large) {
    font-size: 2rem;
    padding: 12px 10px 12px 16px;
  }

  .full-grid-story &,
  .card-grid-story & {
    font-size: 4rem;
    padding: 16px 14px 16px 22px;
  }

  .object-item & {
    font-size: $font-large1;
    padding: 7px 6px 7px 9px;
    top: 7px;
    #{$default-direction}: 7px;
  }

  .playlist & {
    background: rgba(0, 0, 0, 0.4);
    top: 5px;
    #{$default-direction}: 5px;
    font-size: $font-medium1;
    padding: 6px 5px 6px 7px;
  }
}

.playlist-wrapper {
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  min-height: 245px;
  margin-bottom: 2rem;
}

span.author {
  font-family: $font-primary;
  font-weight: 700;
  font-style: italic;
  color: $primary-color;

  .urdu-theme & {
    font-style: normal;
  }
}

.headlines-list {
  list-style: none;
  border-#{$default-direction}: 1px solid #fff;
  margin: 16px 0 0 8px;
  padding: 0;

  a {
    display: block;
    letter-spacing: normal;
    line-height: 1.25;
    font-family: $font-secondary;
    font-weight: 700;
    color: $white;

    &:hover {
      color: $primary-color;
    }
  }

  .abs-bottom-element & {
    a {
      color: $white;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .half-card-grid-story & {
    margin-top: 1.25rem;

    li {
      margin-top: 1.25rem;
    }
  }

  &.has-separator {
    li {
      padding: 0.75rem 0 0.75rem 1.5rem;
      border-top: 1px solid #dadfe1;
      margin-top: 0;

      &:before {
        top: 13px;
      }

      [dir="rtl"] & {
        padding: 0.75rem 1.5rem 0.75rem 0;
      }
    }
  }

  li {
    .eng-theme &,
    .turkish-theme & {
      font-size: $font-small3;
    }

    font-weight: 600;
    padding-#{$default-direction}: 2.2em;
    position: relative;
    margin-top: 0.5rem;
    line-height: 1.2;

    [dir="rtl"] & {
      line-height: 1.4;
    }

    &:before {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #ec1a2e;
      top: -1px;
      #{$default-direction}: 8px;
      display: block !important;
      padding-#{$opposite-direction}: 15px;
      background-image: url(../img/svg/white_arrow.svg);
      background-repeat: no-repeat;
      background-position: $default-direction center;
      background-size: 16px;

      .urdu-theme & {
        top: -7px;
      }

      [dir="rtl"] & {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        top: 2px;
      }
    }
  }
}

.headlines-list.hrefblack a {
  color: black;
  :hover {
    color: $primary-color;
  }
}

.date-field {
  font-size: $font-small2;
  color: $medium-gray;
}

section.section.bottom-spacer--s {
  @include breakpoint(small only) {
    margin-bottom: 0.5rem !important;
  }
}

.section-wrapper {
  margin-bottom: 2rem;
}

.section-title {
  //margin-bottom: 1rem;
  padding-top: 20px;
  color: $black;
  position: relative;

  h2 {
    font-family: $font-secondary;
    font-weight: 700;
    margin-bottom: 12px;
    .eng-theme &,
    .turkish-theme & {
      font-size: $font-large1;
    }

    .turkish-theme & {
      text-transform: uppercase;
    }
  }

  a:not(.see-all) {
    color: $black;

    &:hover {
      color: $primary-color;
    }
  }

  &.has-dot {
    h2,
    h3,
    h4,
    h5 {
      &:before {
        display: inline-block;
        content: " ";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #ec1a2e;
        margin-#{$opposite-direction}: 8px;

        .eng-theme &,
        .turkish-theme & {
          margin-top: 1px;
          vertical-align: top;
        }
      }
    }
  }

  &.has-logo {
    h2,
    h3,
    h4 {
      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        background: transparent url("../img/logo-icon.png") no-repeat center $default-direction;
        background-size: 30px 30px;
        width: 30px;
        height: 30px;
        margin-#{$opposite-direction}: 0.5rem;
        position: relative;
        top: -3px;

        [dir="rtl"] & {
          top: 1px;
        }
      }
    }
  }
}

.see-all {
  display: inline-block;
  color: $primary-color;
  font-size: 0.9em;
  font-weight: 600;
  position: absolute;
  padding-#{$opposite-direction}: 21px;
  #{$opposite-direction}: 0;
  top: 50%;
  line-height: 1.2;
  text-transform: capitalize;
  vertical-align: middle;
  margin-top: -9px;

  &:hover {
    color: $black;

    &:after {
      color: $black;
    }
  }

  &:after {
    content: "\e90a";
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-weight: normal;
    font-family: "icomoon";
    font-size: 0.5rem;
    border-radius: 50%;
    border: 1px solid $primary-color;
    position: absolute;
    top: 0;
    #{$opposite-direction}: 0;
    text-align: center;
    color: $primary-color;
    text-indent: 1px;

    [dir="rtl"] & {
      content: "\e908";
    }
  }
}

.category-field {
  font-family: $font-secondary;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 1.2;
  margin-bottom: 8px;

  .eng-theme &,
  .turkish-theme & {
    font-size: $font-xsmall;
  }

  .turkish-theme & {
    text-transform: capitalize;
  }

  [dir="rtl"] & {
    font-size: $font-xsmall;
  }

  .urdu-theme & {
    font-size: $font-medium1;
  }
}
.category-field1 {
  font-family: $font-secondary;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  a {
    color: #337e81;
  }

  .eng-theme &,
  .turkish-theme & {
    font-size: $font-xsmall;
  }

  .turkish-theme & {
    text-transform: capitalize;
  }

  [dir="rtl"] & {
    font-size: $font-xsmall;
  }

  .urdu-theme & {
    font-size: $font-medium1;
  }
}

.media-object {
  position: relative;
}

.objects-list {
  .cards & {
    .object-item {
      .media-object {
        .media-object-section {
          //height: 100%;
        }

        .media-object-img {
          height: 100%;
        }

        &.media-video,
        &.media-audio {
          .media-object-img {
            &:before {
              background: rgba(236, 26, 46, 0.8);
              color: $white;
              border-radius: 50%;
              cursor: pointer;
              text-align: center;
              content: "\e90b";
              position: absolute;
              font-family: "icomoon";
              width: 32px;
              height: 32px;
              line-height: 32px;
              font-size: 1.25rem;
              z-index: 1;
              bottom: 0;
              text-indent: 6px;
              margin: 0.5rem;

              [dir="rtl"] & {
                text-indent: -3px;
              }

              @include breakpoint(large) {
                top: -24px;
                #{$opposite-direction}: 0;
              }
            }
          }
        }

        &.media-audio {
          .media-object-img {
            &:before {
              content: "\e91e";
            }
          }
        }
      }
    }
  }

  &.has-separator {
    .object-item {
      min-height: 69px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .media-object {
        position: relative;
        margin-bottom: 0;

        &.media-video,
        &.media-audio {
          .media-object-img {
            &:before {
              background: rgba(236, 26, 46, 0.8);
              color: $white;
              border-radius: 50%;
              cursor: pointer;
              text-align: center;
              content: "\e90b";
              position: absolute;
              font-family: "icomoon";
              width: 32px;
              height: 32px;
              line-height: 32px;
              font-size: 1.25rem;
              z-index: 1;
              bottom: 0;
              text-indent: 6px;
              margin: 0.5rem;

              [dir="rtl"] & {
                text-indent: -3px;
              }
            }
          }
        }

        &.media-audio {
          .media-object-img {
            &:before {
              content: "\e91e";
            }
          }
        }
      }

      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $gainsboro;
    }
  }
}

.media-object-img {
  position: relative;

  img {
    max-width: 128px;
    @include breakpoint(medium) {
      max-width: 103px;
    }
    @include breakpoint(large) {
      max-width: 128px;
    }
  }

  .block-wrapper & {
    img {
      max-width: 100px;
    }
  }
}

.entry-title {
  h1 {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.1;
    margin-top: 24px;
    margin-bottom: 24px;
    @include breakpoint(medium) {
      font-size: 2.5rem;
    }
    @include breakpoint(large) {
      font-size: rem-calc(54);
    }
    .entry-video-container & {
      margin-bottom: 16px;
    }

    [dir="rtl"] & {
      line-height: 1.4;
      font-size: 1.5rem;
      @include breakpoint(medium) {
        font-size: rem-calc(28);
      }
      @include breakpoint(large) {
        font-size: rem-calc(34);
      }
    }
  }
}

.entry-subtitle {
  margin-bottom: 16px;
  h2 {
    color: #555555;
    font-family: $font-secondary;
    font-size: $font-medium1;
    font-weight: 300;
    margin-bottom: 0;
    @include breakpoint(medium) {
      font-size: 1.25rem;
    }
  }
}

.entry-meta {
  line-height: 1.5em;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .entry-video-container & {
    margin-bottom: 0;
  }
}

.entry-meta-field {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-family: $font-secondary;
  font-size: $font-medium1;

  .urdu-theme & {
    // font-size: rem-calc(20);
  }
  [dir="rtl"] & {
    font-family: $font-primary;
  }

  &.entry-meta-author {
    display: block;
  }

  &.entry-meta-author {
    > a {
      font-family: $font-primary;
      font-style: italic;
      font-size: $font-medium3;

      [dir="rtl"] & {
        font-size: $font-medium1;
        font-style: normal;
      }

      .urdu-theme & {
        font-style: normal;
      }
    }
  }

  span,
  time {
    font-weight: 300;
    color: #555555;

    .urdu-theme & {
      word-spacing: 4px;
    }
  }

  a {
    font-weight: 700;
  }

  @include breakpoint(small only) {
    margin-bottom: 0.2rem;
  }

  &:after {
    content: " |";
    padding: 0 4px 0 4px;
    color: #555555;
    line-height: 1;
    font-size: $font-small2;
    vertical-align: middle;
  }

  &:last-child:after {
    display: none;
  }
}

.entry-reading-time {
  color: #555555;
}
.entry-meta-comment {
  a {
    color: #555555;
    font-weight: 300;
  }
}

.entry-meta-shares-count {
  color: $primary-color;

  span {
    color: $primary-color;
    padding-right: 4px;
  }
}

.author-twitter {
  [dir="rtl"] & {
    display: inline-block;
    direction: ltr;
  }

  a {
    color: #555555;
    text-decoration: none;
    font-weight: 300;
    font-style: normal;
    font-size: $font-medium1;

    [dir="ltr"] & {
      &:before {
        content: "|";
        padding: 4px;
        color: #555;
        line-height: 1;
        font-size: $font-small2;
        vertical-align: middle;
      }
    }

    [dir="rtl"] & {
      &:after {
        content: "|";
        padding: 4px;
        color: #555;
        line-height: 1;
        font-size: $font-small2;
        vertical-align: middle;
      }
    }
  }
}

.entry-meta-field {
  margin-bottom: 10px;
}

.entry-meta-wrapper {
  @include breakpoint(large) {
    &:not(.has-avatar &) {
      > .grid-x {
        > .cell.shrink {
          max-width: 60%;
        }
      }
    }
  }
}

.entry-summary {
  margin-bottom: 16px;
  h4 {
    margin-bottom: 16px;
  }
  ul {
    li {
      margin-bottom: 16px;
      font-size: $paragraph-font-size;
    }
  }
}

.entry-article-topper {
  padding: 0 1rem;
  @include breakpoint(large) {
    @include xy-grid-container();
    padding: 0;
  }
}

.entry-header {
  &.has-avatar {
    .entry-title {
      h1 {
        font-style: italic;
        [dir="rtl"] & {
          font-style: normal;
        }

        .urdu-theme & {
          font-style: normal;
        }
      }
    }

    .entry-subtitle {
      margin-top: 10px;
      margin-bottom: 10px;
      @include breakpoint(small only) {
        margin-bottom: 1rem;
      }
      @include breakpoint(medium) {
        float: $opposite-direction;
        width: calc(100% - 157px);
      }
    }

    .entry-meta-wrapper {
      @include breakpoint(medium) {
        float: $opposite-direction;
        width: calc(100% - 157px);
      }

      > .grid-x > .cell.shrink {
        max-width: 100%;
        @include breakpoint(medium) {
          max-width: 75%;
        }
        @include breakpoint(large) {
          max-width: 50%;
        }
      }
    }
  }
}

.share-floater {
  position: fixed;
  display: none;
  #{$default-direction}: 0;
  top: 20px;
  border-#{$opposite-direction}: 1px solid $black-400;
  padding: 10px 24px;
  @include breakpoint(medium down) {
    display: none;
  }
  .socials-btns {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    .social-btn {
      background-color: #6d6d6d;
      font-size: 14px;
      margin: 0;
      span {
        font-size: 14px;
      }
    }
  }
}

.share-block {
  margin-bottom: 1rem;
  display: inline-block;
  vertical-align: middle;
  .entry-video-container & {
    margin-bottom: 0;
  }
  .page-node & {
    .socials-btns {
      display: flex;

      .social-btn {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-#{$opposite-direction}: 3px;
        margin-#{$default-direction}: 0;
      }
    }
  }
}
.follow-block {
  margin-bottom: 1rem;
  display: inline-block;
  vertical-align: middle;
  .entry-video-container & {
    margin-bottom: 0;
  }
}

.follow-fb-info {
  font-size: $font-small1;
  line-height: 14px;
  margin-#{$default-direction}: 8px;
  color: #888;
  display: inline-block;
  vertical-align: middle;
}

.caption-field,
.dnd-caption-wrapper {
  font-family: $font-secondary;
  font-weight: 300;
  font-size: $font-medium1;
  color: $medium-gray;
  line-height: 1.2;
  background: #f7f7f7;
  padding: 6px 10px;

  .urdu-theme & {
    //font-size: 1.25rem;
  }
}

.entry-article {
  &.shifted-area {
    position: relative;
    max-width: 800px;
    background-color: $white;
    width: 100%;
    margin: 0 auto;
    @include breakpoint(medium) {
      padding-#{$default-direction}: 82px;
      padding-#{$opposite-direction}: 82px;
    }
    @include breakpoint(large) {
      padding-top: 50px;
      transform: translateY(-230px);
    }

    .share-block {
      display: none;
      @include breakpoint(medium) {
        display: block;
        position: absolute;
        #{$opposite-direction}: 90%;
        z-index: 1;
        top: 20px;
      }
      @include breakpoint(large) {
        top: 220px;
        #{$opposite-direction}: 100%;
        padding-#{$opposite-direction}: 30px;
      }

      .social-btn.round {
        margin-bottom: 0.75rem;
      }
    }
  }
}

.content-wrap-holder {
  width: 100%;
  @include breakpoint(medium) {
    margin: 0 auto;
    max-width: 636px;
  }
  @include breakpoint(large) {
    margin-#{$default-direction}: 0;
    margin-#{$opposite-direction}: 0;
    padding: 0;
    width: calc(100% - 344px);
  }
}

.sidebar-wrap-holder {
  display: none;
  width: 308px;
  @include breakpoint(large) {
    display: block;
  }
}

.entry-media {
  position: relative;
  margin-bottom: 24px;
  .entry-video-container & {
    margin-bottom: 0;
  }
}

.entry-content {
  font-weight: 300;
  font-size: $font-medium1;
  line-height: 26px;
  margin-bottom: 1.5rem;

  > h2 {
    font-size: $font-large2;
    font-weight: 700;
  }

  > h1,
  > h2,
  > h3 {
    margin: 2rem 0 18px;
    clear: both;
  }

  @include breakpoint(medium) {
    font-size: $font-medium4;
  }

  // [dir="rtl"] & {
  //   font-size: $font-medium2;
  //   line-height: 1.6;
  // }

  [dir="rtl"] .persian-theme & {
    font-size: $font-medium3;
  }

  .urdu-theme & {
    //font-size: $font-large2;
    line-height: 1;

    p {
      margin-bottom: 1rem;
    }
  }

  /*> p:last-of-type:after {
    content: " ";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #ec1a2e;
    border-radius: 50%;
    margin-#{$default-direction}: 6px;
    position: relative;
    top: 2px;
  }*/
  table {
    max-width: 100%;
    @include breakpoint(small only) {
      line-height: 1.4;
      th {
        font-size: $font-small3;
      }
      td {
        font-size: $font-small4;
      }
    }
  }

  p {
    // font-size: inherit;
    // font-weight: inherit;
    // line-height: inherit;
  }
}

blockquote {
  .entry-content & {
    border: 0;
    font-size: $font-medium3;

    > p:first-child {
      color: #888888;
      font-style: italic;

      .urdu-theme & {
        font-style: normal;
      }

      &:before {
        margin-top: 0;
        background-size: contain;
        width: 38px;
        height: 16px;
        margin-bottom: 5px;
        margin-#{$opposite-direction}: 0;
      }

      [dir="rtl"] & {
        font-size: $font-medium1;
      }
    }
  }
}

.page-taxonomy-term-61 .entry-content,
blockquote {
  > p:first-of-type:before {
    -webkit-print-color-adjust: exact;
    content: " ";
    width: 80px;
    height: 47px;
    float: $default-direction;
    background-image: url(../img/svg/quote.svg);
    background-repeat: no-repeat;
    margin: 4px 24px 24px 0;

    [dir="rtl"] & {
      margin: 4px 0 24px 24px;
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
    }
  }
}

.entry-tags {
  margin-bottom: 1.5rem;
  padding: 12px 0;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;

  span {
    text-transform: uppercase;
    font-family: $font-secondary;
    font-size: $font-small4;
    font-weight: 700;
    color: $secondary-color;
    margin-#{$opposite-direction}: 10px;
  }

  a {
    font-size: $font-small1;
    line-height: 1.5;
    font-weight: 700;
    padding: 1px 8px 0;
    margin: 4px 8px 4px 0;
    text-transform: uppercase;
    font-family: $font-secondary;
    display: inline-block;
  }
}

.gigya-newsletter {
  margin-bottom: 1.5rem;
}

.entry-promoted-stories {
  margin-bottom: 1.5rem;
}

.box-title {
  margin-bottom: 0.5rem;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-secondary;
    margin-bottom: 0;
    opacity: 0.5;
    color: $secondary-color;
  }

  /*&:after {
    content: " ";
    display: block;
    background: #ec1a2e;
    height: 5px;
    width: 50px;
    margin-bottom: 8px;
  }*/
}

.block-wrapper {
  margin-bottom: 24px;
}

.ad-block-header {
  margin-bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #ffffff;
  padding: 16px;
}

.block-title {
  margin-bottom: 0.5rem;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-secondary;
    margin-bottom: 0;
    opacity: 0.5;
    color: $secondary-color;
  }

  /*&:after {
    content: " ";
    display: block;
    background: #ec1a2e;
    height: 5px;
    width: 50px;
    margin-bottom: 8px;
  }*/
}

.injected-block,
.injected-blocks {
  width: 100%;
  padding: 0 10px;
  border-#{$default-direction}: 1px solid #979797;
  border-#{$opposite-direction}: 1px solid #979797;
  float: $default-direction;
  font-family: $font-primary;
  @include breakpoint(medium) {
    width: 50%;
    margin-#{$opposite-direction}: 16px;
  }
  @include breakpoint(large) {
    margin-#{$default-direction}: 0;
  }

  .views-field-nid {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      margin-bottom: 10px;
      line-height: 1.2em;
      display: flex;

      > div:first-child {
        margin-#{$opposite-direction}: 8px;
        flex-basis: 33%;
        flex-shrink: 0;
      }
    }
  }

  a {
    color: $secondary-color;
    font-size: $font-small3;
    font-weight: 700;
    font-family: $font-primary;

    &:hover {
      color: $primary-color;
    }
  }

  h6 {
    font-family: $font-secondary;
    font-weight: 700;
    opacity: 0.5;
    color: $secondary-color;
  }
}

.injected-block-watch-more,
.injected-blocks-watch-more {
  width: 48%;
  background-color: $black;
  padding: 0.5rem;
  @include breakpoint(medium) {
    width: 36%;
  }

  ul {
    li {
      flex-wrap: wrap;

      > div:first-child {
        flex-basis: 100%;
        margin: 0 0 8px 0;
      }
    }
  }

  a {
    flex: 0 0 100%;
    font-family: $font-primary;
    font-size: $font-medium2;
    color: $white;
    padding-top: 6.5px;
  }

  h6 {
    color: #ffffff;
    opacity: 1;
  }
}

.breadcrumbs-wrapper {
  display: block;
  font-family: $font-secondary;
  font-weight: 500;
  padding: 0 1rem;
  @include breakpoint(large) {
    padding-top: 24px;
  }
  .breadcrumbs {
    margin-bottom: 0;
    li {
      font-size: 16px;
      a {
        color: $primary-color;

        &.breadcrumbs__home {
          font-size: 14px;
          display: inline-block;
          color: $gray;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &:not(:last-child)::after {
        content: $icon-arrow-left2;
        font-family: "icomoon";
        font-size: 9px;
        margin-left: 8px;
        margin-right: 8px;
        color: $light-gray;
      }
    }
  }
}

.entry-media-inner {
  margin-bottom: 16px;
  a {
    color: inherit;
  }
}

.entry-media-inner-img {
  position: relative;
}

.gallery-count {
  font-size: 1.25rem;
  padding: 10px;
  line-height: 1;
  position: absolute;
  bottom: 6px;
  #{$opposite-direction}: 6px;
  background: hsla(0, 0%, 93%, 0.666);
}

.gallery-trigger {
  cursor: pointer;
}

.grid-mod-gallery {
  .images {
    ul {
      li {
        position: relative;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        float: $default-direction;
        border-#{$default-direction}: 10px solid #fff;
        border-#{$opposite-direction}: 10px solid #fff;
        border-top: 10px solid #fff;
        height: 20%;
        width: 25%;

        &:first-child {
          height: 78%;
          width: 100%;
          border-#{$default-direction}: none;
          border-#{$opposite-direction}: none;
          border-top: none;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
}

.grid-mod-gallery {
  box-shadow: none;
  border: 1px solid #d6d6d6;
  display: flex;
  flex-direction: column;
  height: 600px;
}

@media all and (max-width: 480px) {
  .grid-mod-gallery {
    height: 600px;
  }
}

.grid-mod-gallery .gallery-title {
  font-size: 20px !important;
  font-weight: 600;
  line-height: 24px !important;
  padding: 20px !important;
  height: auto !important;
}

.grid-mod-gallery .images {
  flex: 1;
}

.grid-mod-gallery .images ul {
  height: 100%;
  list-style: none;
}

.grid-mod-gallery li {
  position: relative;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  float: left;
}

.grid-mod-gallery li a {
  display: block;
  height: 100%;
  color: #fff;
}

.grid-mod-gallery li a:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #ec1a2e;
}

.grid-mod-gallery li:first-child a {
  float: $default-direction;
  width: 50%;
}

.grid-mod-gallery li:first-child a:hover {
  background: 0 0;
}

.grid-mod-gallery li:first-child a:hover span {
  opacity: 0.5;
}

.grid-mod-gallery li:last-child a {
  background-color: #dadfe1;
  color: #555;
  padding-top: 30px;
  text-align: center;
}

.grid-mod-gallery li.image-small {
  background-size: inherit;
  background-position: center center;
}

.grid-mod-gallery .pager {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 50%;
  //margin-top: -30px;
  transform: translateY(-50%);
  line-height: 1;
  padding: 1rem;

  i {
    font-size: 28px;
    vertical-align: top;
  }

  &.next {
    #{$opposite-direction}: 15px;
  }

  &.prev {
    #{$default-direction}: 15px;
  }
}

@media only screen and (min-width: 620px) {
  .grid-mod-gallery .gallery-title {
    min-height: 60px;
  }
}

.grid-mod-gallery a:hover .pager {
  background: rgba(0, 0, 0, 0.9);
}

.grid-mod-gallery .additional-photos {
  display: block;
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
  margin-bottom: 0;
  font-family: sans serif;

  i {
    margin-#{$opposite-direction}: 5px;
    line-height: 1;
    display: inline-block;
    vertical-align: text-top;
  }
}

.grid-mod-gallery .label {
  font-size: $font-small3;
  line-height: 18px;
  background-color: transparent;
  color: $emperor;
}

.grid-mod-gallery ul {
  list-style: none;
  height: 400px;
  margin: 0;
}

.grid-mod-gallery li {
  height: 25%;
  width: 50%;
}

.grid-mod-gallery li:first-child {
  height: 50%;
  width: 100%;
}

@media (min-width: 400px) {
  .grid-mod-gallery ul {
    height: 500px;
  }
}

@media (min-width: 500px) {
  .grid-mod-gallery ul {
    height: 540px;
  }
}

.weight-1 .grid-mod-gallery li:nth-child(2n) {
  border-#{$opposite-direction}: 1px solid #fff;
  border-top: 1px solid #fff;
}

.weight-1 .grid-mod-gallery li:nth-child(2n + 1) {
  border-top: 1px solid #fff;
}

.grid-mod-gallery-wrapper .grid-mod-gallery li {
  border-#{$default-direction}: 10px solid #fff;
  border-#{$opposite-direction}: 10px solid #fff;
  border-top: 10px solid #fff;
  height: 20%;
  width: 25%;
}

.grid-mod-gallery-wrapper .grid-mod-gallery li:first-child {
  height: 78%;
  width: 100%;
  border-#{$default-direction}: none;
  border-#{$opposite-direction}: none;
  border-top: none;
  border-bottom: 1px solid #fff;
}

.grid-mod-gallery-wrapper .grid-mod-gallery li:last-child a {
  padding-top: 20px;
}

.weight-3 .grid-mod-gallery li,
.weight-4 .grid-mod-gallery li {
  border-bottom: 10px solid #fff;
  border-#{$default-direction}: 10px solid #fff;
  border-#{$opposite-direction}: 10px solid #fff;
  height: 25%;
  width: 20%;
}

.weight-3 .grid-mod-gallery li:first-child,
.weight-4 .grid-mod-gallery li:first-child {
  border-#{$opposite-direction}: 1px solid #fff;
  border-#{$default-direction}: none;
  border-top: none;
  height: 100%;
  width: 80%;
}

.weight-3 .grid-mod-gallery li:last-child a,
.weight-4 .grid-mod-gallery li:last-child a {
  padding-top: 24px;
}

.weight-3 .grid-mod-gallery .additional-photos,
.weight-4 .grid-mod-gallery .additional-photos {
  font-size: 48px;
  line-height: 52px;
  margin-bottom: 12px;
  margin-bottom: 0;
}

.grid-mod-gallery-wrapper .grid-mod-half-height h1,
.weight-3 .grid-mod-gallery .label,
.weight-4 .grid-mod-gallery .label {
  font-size: 26px;
  line-height: 30px;
}

@media only screen and (min-width: 620px) {
  .full-article .grid-mod-gallery li {
    border-#{$default-direction}: 10px solid #fff;
    border-#{$opposite-direction}: 10px solid #fff;
    border-top: 10px solid #fff;
    height: 20%;
    width: 25%;
  }

  .full-article .grid-mod-gallery li:first-child {
    height: 78%;
    width: 100%;
    border-#{$default-direction}: none;
    border-#{$opposite-direction}: none;
    border-top: none;
    border-bottom: 1px solid #fff;
  }

  .full-article .grid-mod-gallery li:last-child a {
    padding-top: 20px;
  }
}

@media all and (max-width: 999px) {
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(2n) {
    border-#{$opposite-direction}: 1px solid #fff;
    border-#{$default-direction}: 0 none;
    border-top: 1px solid #fff;
  }

  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(2n + 1) {
    border-top: 1px solid #fff;
    border-#{$default-direction}: 0 none;
    border-#{$opposite-direction}: 0 none;
  }

  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(n + 2) {
    width: 50%;
    height: 25%;
  }

  .grid-mod-gallery-wrapper .grid-mod-gallery li:first-child {
    height: 50%;
  }
}

@media all and (min-width: 668px) {
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(n + 2) {
    height: 25%;
  }
}

@media all and (min-width: 1000px) {
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(n + 2) {
    height: 20%;
  }
}

body.fullscreen-gallery {
  overflow: hidden;
}

.gallery-specific-trigger {
  cursor: pointer;
}

.full-gallery,
.full-gallery-specific {
  visibility: hidden;
  opacity: 0;
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  position: fixed;
  top: 0;
  bottom: 0;
  #{$opposite-direction}: 0;
  #{$default-direction}: 0;
  z-index: 6050000;
  background: #fff;
}

.full-gallery .close,
.full-gallery-specific .close {
  float: $opposite-direction;
  font-size: 32px;
  padding: 14px;
  color: $secondary-color;
  position: absolute;
  top: 0;
  #{$opposite-direction}: 0;
  line-height: 1;
}

.full-gallery .close:hover,
.full-gallery-specific .close:hover {
  color: #ec1a2e;
}

.full-gallery .gallery-title,
.full-gallery-specific .gallery-title {
  font-family: $font-secondary;
  font-size: $font-large1;
  line-height: 24px;
  padding: 20px;
  padding-#{$opposite-direction}: 4rem;

  .gallery-icon {
    color: $primary-color;
  }
}

.full-gallery .scroller,
.full-gallery-specific .scroller {
  height: 100%;
  overflow: auto;
}

.full-gallery .gallery-wrapper,
.full-gallery-specific .gallery-wrapper {
  height: 100vh;
}

.gallery-inner {
  height: 100%;
}

.full-gallery .images,
.full-gallery-specific .images {
  position: relative;
  float: $default-direction;
  width: 100%;
  margin-bottom: 20px;
  height: 100%;
  /** {
    max-height: 100%;
  }*/
}

.full-gallery .images li,
.full-gallery-specific .images li {
  position: absolute;
  top: 0;
  bottom: 0;
  #{$opposite-direction}: 0;
  #{$default-direction}: 0;
  text-align: center;
}

.full-gallery ul,
.full-gallery-specific ul {
  list-style: none;
  margin: 0;
}

.full-gallery li,
.full-gallery-specific li {
  display: none !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  //transition: all 2s linear;
}

.full-gallery li.active,
.full-gallery-specific li.active {
  display: block !important;
}

.full-gallery li img,
.full-gallery-specific li img {
  max-height: 100%;
}

/*
@media only screen and (min-width: 620px) {
  .full-gallery .icon-arrow-left, .full-gallery .icon-arrow-right {
    margin-top: -30px
  }

  .full-gallery .icon-arrow-#{$default-direction}:before, .full-gallery .icon-arrow-#{$opposite-direction}:before {
    font-size: 60px
  }
}
*/

.full-gallery .pager,
.full-gallery-specific .pager {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 28px;
    vertical-align: top;
    background: rgba(0, 0, 0, 0.5);
    //border-radius: 50%;
    color: #fff;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
    padding: 1rem;

    &:before {
      display: block;
    }

    [dir="rtl"] & {
      &:before {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }
    }

    &.icon-arrow-left {
      #{$default-direction}: 0;
      /*[dir="rtl"] & {
        #{$default-direction}: auto;
        #{$opposite-direction}: 0;
      }*/
    }

    &.icon-arrow-right {
      #{$opposite-direction}: 0;
      /*[dir="rtl"] & {
        #{$default-direction}: 0;
        #{$opposite-direction}: auto;
      }*/
    }
  }
}

.full-gallery .pager.prev,
.full-gallery-specific .pager.prev {
  width: 50%;
  #{$default-direction}: 0;
  /*[dir="rtl"] & {
    #{$default-direction}: auto;
    #{$opposite-direction}: 0;
  }*/
}

.full-gallery .pager.next,
.full-gallery-specific .pager.next {
  #{$opposite-direction}: 0;
  width: 50%;
  /*[dir="rtl"] & {
    #{$default-direction}: 0;
    #{$opposite-direction}: auto;
  }*/
}

.gallery-inner .pager.prev,
.gallery-inner .pager.next {
  &:hover {
    i {
      background-color: $primary-color;
    }
  }
}

.full-gallery .legends,
.full-gallery-specific .legends {
  padding: 20px;
}

.full-gallery .legends h2,
.full-gallery-specific .legends h2 {
  font-family: $font-secondary;
  font-size: $font-large1;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 10px;
}

.full-gallery .legends h2 .counter,
.full-gallery-specific .legends h2 .counter {
  color: #ec1a2e;
  padding-#{$opposite-direction}: 0.5rem;
}

.full-gallery .legends p,
.full-gallery-specific .legends p {
  font-size: $font-small3;
  line-height: 18px;
  margin-bottom: 10px;
}

.full-gallery .legends .credits,
.full-gallery-specific .legends .credits {
  color: #888;
}

.full-gallery .legends :last-child,
.full-gallery-specific .legends :last-child {
  margin-bottom: 0;
}

.full-gallery .legends a,
.full-gallery-specific .legends a {
  color: #ec1a2e !important;
}

.full-gallery .legends a:hover,
.full-gallery-specific .legends a:hover {
  color: #940c19 !important;
}

.full-gallery .ad-button,
.full-gallery-specific .ad-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: $secondary-color;
}

.full-gallery .ad-button img,
.full-gallery-specific .ad-button img {
  display: block;
  margin: 0 auto;
}

.full-gallery .ad-mpu,
.full-gallery-specific .ad-mpu {
  text-align: center;
  background-color: #f5f5f5;

  .premium-content {
    width: 300px;
  }
}

@media only screen and (min-width: 930px) {
  .full-gallery .ad-button,
  .full-gallery-specific .ad-button {
    display: none;
  }

  .full-gallery .ad-mpu,
  .full-gallery .ad-mpu img,
  .full-gallery-specific .ad-mpu,
  .full-gallery .ad-mpu img {
    display: block;
    width: auto;
  }

  .full-gallery .ad-mpu,
  .full-gallery-specific .ad-mpu {
    float: $default-direction;
    padding: 20px;
  }

  .full-gallery .images,
  .full-gallery-specific .images {
    float: $default-direction;
    width: calc(100% - 340px);
    margin-bottom: 0;
  }

  .full-gallery .legends,
  .full-gallery-specific .legends {
    float: $opposite-direction;
    width: 340px;
    background: rgba(0, 0, 0, 0.03);
  }
}

.full-gallery.gallery-open,
.full-gallery-specific.gallery-open {
  visibility: visible;
  opacity: 1;
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.premium-content {
  padding: 5px;
  margin: 0 auto 0.75rem;

  .sidebar-wrap & {
    margin-bottom: 1.5rem;
  }

  @include breakpoint(medium) {
    margin: 0 auto;
  }
  @include breakpoint(medium only) {
    padding: 5px 0 0;
  }

  .title {
    font-size: $font-small1;
    padding: 4px 8px 8px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.2);
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-family: $font-secondary;
      font-size: $font-small1;
      font-weight: 700;
      line-height: 1;
      margin: 0;
    }
  }
}

.dmpu {
  text-align: center;
  @include breakpoint(large) {
    height: 600px;
    margin: 0;
  }
}

.mpu {
  text-align: center;
  @include breakpoint(large) {
    margin-bottom: 0.2rem;
  }
}

.right-position {
  > .grid-x {
    flex-direction: column-reverse;
    @include breakpoint(medium) {
      flex-direction: row-reverse;
    }
  }
}

.left-position {
  > .grid-x {
    @include breakpoint(medium) {
      flex-direction: row;
    }
  }
}

.eight-articles-dmpu {
  .articles-list {
    &:not(.cards) {
      .objects-list.has-separator .object-item {
        @include breakpoint(medium only) {
          &:last-child {
            margin-bottom: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #d6d6d6;
          }
        }
      }

      .media-object {
        @include breakpoint(large) {
          .media-object-img img {
            max-width: 138px;
          }
        }
      }
    }
  }
}

.eight-articles-dmpu,
.six-articles-mpu {
  .objects-list.has-separator .object-item {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #d6d6d6;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.six-articles-mpu,
.seven-articles {
  .media-object-img {
    position: relative;

    img {
      @include breakpoint(medium only) {
        max-width: 126px;
      }
    }
  }

  .article-item-author {
    display: none;
  }

  @include breakpoint(small only) {
    .article-item.article-hero {
      //margin-bottom: 0.5rem;
    }
    .articles-list {
      margin-bottom: 0.5rem;
    }
    .cell.medium-12 {
      .articles-list {
        .objects-list.has-separator {
          padding-top: 0.5rem;
          border-top: 1px solid $gainsboro;
        }
      }
    }
  }
  @include breakpoint(medium only) {
    .cell.medium-12 {
      .articles-list {
        .objects-list {
          position: relative;
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 12px;
        }

        .object-item {
          width: calc(33.33% - 8px);
          border: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .article-item-author {
          display: block;
          font-size: $font-medium1;
        }

        .article-item-title h6 {
          font-size: $font-medium4;
          margin-top: 0.5rem;
        }

        .media-object {
          height: 100%;
          margin-bottom: 0;
          flex-direction: column-reverse;
          border: 1px solid rgba(0, 0, 0, 0.2);
          padding-bottom: 0;

          .media-object-section:first-child {
            padding: 0;
          }

          .media-object-section:last-child {
            position: relative;
            min-height: 160px;
            width: 100%;
            padding: 16px;
          }

          .media-object-img {
            position: relative;

            &:after {
              content: "";
              display: block;
              padding-top: 66.8067%;
              width: 100%;
            }

            img {
              display: block;
              height: 0;
              max-height: 100%;
              max-width: 100%;
              min-height: 100%;
              min-width: 100%;
              width: 0;
              margin: auto;
              position: absolute;
              top: 0;
              #{$default-direction}: 0;
              bottom: 0;
              #{$opposite-direction}: 0;
            }
          }
        }
      }
    }
  }
}

.four-articles,
.cards-articles,
.two-articles-compact {
  .article-item {
    border: 1px solid rgba(0, 0, 0, 0.2);

    &.article-hero {
      .article-item-img {
        height: auto;
      }

      &.media-video,
      &.media-audio {
        .article-item-img {
          &:before {
            background: rgba(236, 26, 46, 0.8);
            color: $white;
            border-radius: 50%;
            cursor: pointer;
            text-align: center;
            content: "\e90b";
            position: absolute;
            font-family: "icomoon";
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 1.25rem;
            z-index: 1;
            bottom: 0;
            top: -24px;
            #{$opposite-direction}: 0;
            text-indent: 6px;
            margin: 0.5rem;

            [dir="rtl"] & {
              text-indent: -6px;
            }
          }
        }
      }

      &.media-audio {
        .article-item-img {
          &:before {
            content: "\e91e";
          }
        }
      }
    }
  }

  .article-item-content {
    position: relative;
    width: 100%;
    padding: 1rem;
    @include breakpoint(medium) {
      // min-height: 160px;
      .block-wrapper & {
        min-height: initial;
      }
    }
  }
  .article-item-content1 {
    position: relative;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 40px;
    @include breakpoint(medium) {
      // min-height: 160px;
      .block-wrapper & {
        min-height: initial;
      }
    }
  }

  .article-item-author {
    font-size: $font-medium1;
  }

  .article-item-img {
    height: auto;
  }

  .article-item-desc {
    font-size: $font-small4;
  }

  .article-item-title {
    h4 {
      font-weight: 500;
      font-size: $font-small3;

      [dir="rtl"] & {
        font-weight: 800;
        font-size: $font-medium2;
      }

      .urdu-theme & {
        font-size: $font-medium4;
        line-height: 0.8;
      }

      @include breakpoint(360px) {
        font-size: $font-medium2;
      }
      @include breakpoint(large) {
        font-size: $font-medium4;
      }
    }
  }
}

.four-articles1,
.cards-articles,
.two-articles-compact {
  .article-item.article-hero.media-video .abs-bottom-element,
  .article-item.article-hero.media-audio .abs-bottom-element {
    padding-top: 20px;
    margin-top: -80px;
    margin-bottom: 8px;
  }

  .cell.medium-6.large-6 {
    @include breakpoint(small only) {
      width: 97.5%;
      margin-bottom: 0.5rem;
    }
  }

  .article-item {
    border: 1px solid rgba(0, 0, 0, 0.2);

    &.article-hero {
      .article-item-img {
        height: auto;
      }

      &.media-video,
      &.media-audio {
        .article-item-img {
          &:before {
            background: rgba(236, 26, 46, 0.8);
            color: $white;
            border-radius: 50%;
            cursor: pointer;
            text-align: center;
            // content: "\e90b";
            position: absolute;
            font-family: "icomoon";
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 1.25rem;
            z-index: 1;
            bottom: 0;
            top: -24px;
            #{$opposite-direction}: 0;
            text-indent: 6px;
            margin: 0.5rem;

            [dir="rtl"] & {
              text-indent: -6px;
            }
          }
        }
      }

      &.media-audio {
        .article-item-img {
          &:before {
            content: "\e91e";
          }
        }
      }
    }
  }

  .article-item-content {
    position: relative;
    width: 100%;
    padding: 1rem;
    @include breakpoint(medium) {
      // min-height: 160px;
      .block-wrapper & {
        min-height: initial;
      }
    }
  }
  .article-item-content1 {
    position: relative;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 40px;
    @include breakpoint(medium) {
      // min-height: 160px;
      .block-wrapper & {
        min-height: initial;
      }
    }
  }

  .article-item-author {
    font-size: $font-medium1;
  }

  .article-item-img {
    height: auto;
  }

  .article-item-desc {
    font-size: $font-small4;
  }

  .article-item-title {
    h4 {
      font-weight: 500;
      font-size: $font-small3;

      [dir="rtl"] & {
        font-weight: 800;
        font-size: $font-medium2;
      }

      .urdu-theme & {
        //font-size: $font-medium4;
        // font-size: 25px;
        line-height: 1.6;
      }

      @include breakpoint(360px) {
        font-size: $font-medium2;
      }
      @include breakpoint(large) {
        font-size: $font-medium4;
      }
    }
  }

  article.article-item.article-hero.media-video {
    border: 0px;
    margin-bottom: 9px;
  }
}

.two-articles-compact {
  @include breakpoint(medium) {
    .article-item {
      flex-direction: row-reverse;
    }
    .article-item-img {
      width: 50%;
    }
    .article-item-content {
      width: 50%;
    }
  }
}

.three-articles {
  .article-item.article-hero {
    @include breakpoint(medium) {
      height: 220px;
    }
    @include breakpoint(large) {
      height: 300px;
    }

    h4 {
      font-size: $font-medium2;
    }
    .urdu-theme & {
      h4 {
        font-size: 22px;
      }
    }
  }
}

.splash-row {
  > .grid-x > .cell.large-8 {
    > .article-item.media-video,
    > .article-item.media-audio {
      .abs-bottom-element {
        padding-top: 40px;
      }
    }
  }

  .articles-list {
    .article-item.article-hero {
      margin-bottom: 0.5rem;
    }

    &.has-related {
      .article-item.article-hero {
        margin-bottom: 0;
      }

      .abs-bottom-element {
        padding-bottom: 0;
        padding-#{$default-direction}: 8px;
        padding-#{$opposite-direction}: 8px;

        .article-item-title {
          a {
            border-#{$default-direction}: 1px solid #fff;
            padding: 0 0 8px 8px;

            [dir="rtl"] & {
              padding: 0 8px 8px 0;
            }
          }
        }
      }

      .headlines-list {
        border-#{$default-direction}: 1px solid $black;
        padding-top: 0.5rem;
        margin: 0 0 0.5rem 0.5rem;

        [dir="rtl"] & {
          margin: 0 0.5rem 0.5rem 0;
        }

        li {
          margin-top: 0;

          a {
            font-weight: 500;
            line-height: 1.25;

            .urdu-theme {
              // font-size: $font-medium4;
            }
          }
        }
      }
    }
  }
}

.eight-articles {
  .articles-list .objects-list.has-separator {
    .object-item {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.seven-articles,
.two-articles {
  .grid-x {
    @include breakpoint(small only) {
      > div:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@include breakpoint(large) {
  .eight-articles-dmpu .articles-list .article-item-title h6 {
    .eng-theme &,
    .turkish-theme & {
      font-size: $font-medium2;
    }
  }
}

.articles-list {
  /*@include breakpoint(small only) {
    margin-bottom: 0.5rem;
  }*/
  &.cards {
    @include breakpoint(small only) {
      .objects-list {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .object-item {
        width: calc(50% - 3px);
        border: 0 !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
      .article-item-author {
        display: block;
        font-size: $font-medium1;
      }
      .article-item-title h6 {
        font-size: $font-medium1;
        margin-top: 0.5rem;
      }
      .media-object {
        height: 100%;
        margin-bottom: 0;
        flex-direction: column-reverse;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 0;

        .media-object-section:first-child {
          padding: 0;
        }

        .media-object-section:last-child {
          position: relative;
          min-height: 160px;
          width: 100%;
          padding: 16px;
        }

        .media-object-img {
          position: relative;

          &:after {
            content: "";
            display: block;
            padding-top: 66.8067%;
            width: 100%;
          }

          img {
            display: block;
            height: 0;
            max-height: 100%;
            max-width: 100%;
            min-height: 100%;
            min-width: 100%;
            width: 0;
            margin: auto;
            position: absolute;
            top: 0;
            #{$default-direction}: 0;
            bottom: 0;
            #{$opposite-direction}: 0;
          }
        }
      }
    }

    @include breakpoint(large) {
      height: 100%;
      .objects-list {
        position: relative;
        height: 100%;
      }
      .media-object {
        height: 100%;
        margin-bottom: 0;
        @include breakpoint(large) {
          flex-direction: column-reverse;
          border: 1px solid rgba(0, 0, 0, 0.2);
          padding-bottom: 0;
        }

        .media-object-section {
          flex: 1;
        }

        .media-object-section:first-child {
          padding: 0;
        }

        .media-object-section:last-child {
          position: relative;
          //height: 100%;
          width: 100%;
          padding: 16px;
        }

        .media-object-img {
          position: relative;

          img {
            display: block;
            height: 0;
            max-height: 100%;
            max-width: 100%;
            min-height: 100%;
            min-width: 100%;
            width: 0;
            margin: auto;
            position: absolute;
            top: 0;
            #{$default-direction}: 0;
            bottom: 0;
            #{$opposite-direction}: 0;
          }
        }
      }
      .object-item {
        @include breakpoint(large) {
          position: absolute;
          height: 49.4%;
          width: 100%;
          &:last-child {
            margin-bottom: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}

.author-name {
  color: $primary-color;
  font-style: italic;
  font-weight: 700;
  font-size: $font-small3;

  .urdu-theme & {
    font-style: normal;
    // font-size: 20px;
  }
}

.author-headline {
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 1.4;

  .urdu-theme & {
    // font-style: normal;
  }

  .eng-theme &,
  .turkish-theme & {
    font-size: $font-small3;
  }

  a {
    color: $black;

    &:hover {
      color: $primary-color;
    }
  }
}

.three-articles-headshots {
  border-top: 1px solid $gainsboro;
  border-bottom: 1px solid $gainsboro;
  background: $white-smoke;
  margin-bottom: 16px;
  @include breakpoint(medium) {
    .slick-track {
      display: flex;

      .slick-slide {
        display: flex;
        height: auto;
        //align-items: center;
        //justify-content: center;
      }
    }
  }

  .media-object-img {
    position: relative;
    height: 100%;

    a {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: end;
      align-content: end;
      position: relative;
      height: 100%;
    }
  }

  .carousel-items {
    //max-height: 90px;
    overflow-y: hidden;
    padding: 0 2.5rem;
  }

  .media-object {
    margin-bottom: 0;
  }

  .author-name {
    margin-bottom: 8px;
  }

  .media-object-section-content {
    padding: 8px 0;
    @include breakpoint(small only) {
      .author-headline {
        a {
          font-size: $font-small2;
          display: block;
          .urdu-theme & {
            font-size: 20px;
          }
        }
      }
    }
  }

  @include breakpoint(large) {
    .carousel-items {
      padding: 0;
    }
  }

  [dir="rtl"] & {
    .author-headline {
      font-size: $font-small3;
      font-style: normal;
    }
  }

  .urdu-theme & {
    .author-headline {
      font-size: $font-medium3;
      margin-top: 7px;
    }
  }

  .persian-theme & {
    .author-headline {
      font-size: $font-small4;
    }
  }
}

.author-field {
  text-align: center;

  .media-object {
    margin-bottom: 0;
  }

  @include breakpoint(medium) {
    .media-object {
      margin-bottom: 1rem;
    }
    text-align: $default-direction;
  }
}

.author-field-img {
  img {
    max-width: 75px;
  }
}

.author-field-name {
  color: $primary-color;
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  line-height: 1;
  margin-top: 0.5rem;

  [dir="rtl"] & {
    line-height: 1.3;
  }

  .urdu-theme & {
    //font-style: normal;
  }
}

.author-field-job-title {
  font-family: $font-secondary;
  font-size: $font-medium4;
  font-weight: 300;
  color: #888888;
  display: inline-block;
  vertical-align: middle;
}

.author-field-social {
  display: inline-block;
  vertical-align: middle;

  a {
    font-family: $font-secondary;
    font-size: $font-medium4;
    font-weight: 300;
    color: #888888;

    &:before {
      color: #888888;
      content: "|";
      margin-#{$opposite-direction}: 5px;
    }
  }
}

.author-field-bio {
  font-size: $font-medium2;
  line-height: 1.4;
  font-weight: 300;
  @include breakpoint(medium) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.i100-block {
  width: 100%;
}

.inline-gallery {
  font-family: $font-secondary;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  padding: 4px 0 16px;
  margin-bottom: 16px;
}

span.gallery-icon {
  width: 24px;
  height: 18px;
  display: inline-block;
  margin-#{$opposite-direction}: 8px;
}

.inline-gallery-before {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  h4 {
    font-family: inherit;
    font-size: $font-medium3;
    font-weight: 700;
    margin-#{$opposite-direction}: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.2;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .inline-gallery-count {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ec1a2e;
    font:
      700 14px Independent Sans,
      sans-serif;
    cursor: pointer;

    &:hover {
      color: #940c19;
    }
  }
}

.inline-gallery-inner {
  display: flex;
  flex-direction: column;
  height: 49.5%;
  width: 100%;
  position: relative;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.inline-gallery-preview {
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 66%;

  .sizer {
    padding-top: 80%;
  }

  @include breakpoint(medium) {
    width: 75%;
  }

  .chevron-icon {
    position: absolute;
    #{$default-direction}: 0;
    top: 50%;
    z-index: 3;
    width: 50px;
    height: 50px;
    line-height: 50px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    color: $white;
    font-size: $font-large1;

    &.next-icon {
      #{$default-direction}: auto;
      #{$opposite-direction}: 0;
    }

    [dir="rtl"] & {
      #{$default-direction}: auto;
      #{$opposite-direction}: 0;

      &.next-icon {
        #{$default-direction}: 0;
        #{$opposite-direction}: auto;
      }
    }
  }
}

.inline-gallery-thumbnails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 12px;
  @include breakpoint(medium) {
    height: auto;
    flex: 1;
    flex-direction: column;
    margin-top: 0;
    margin-#{$default-direction}: 12px;
  }
}

.gallery-thumbnail {
  position: relative;
  height: calc(100% - 20px);
  width: calc(100% / 3 - 8px);
  cursor: pointer;
  @include breakpoint(medium) {
    width: 100%;
    height: calc(33.3% - 8px);
  }

  .sizer {
    padding-top: 80%;
  }
}

.author-avatar {
  width: 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 50%;
  margin: 16px 8px 0 0;
  background-color: rgba(0, 0, 0, 0.2);

  [dir="rtl"] & {
    //margin: 16px 0 0 8px;
  }

  @include breakpoint(medium) {
    width: 125px;
    height: 125px;
    margin-#{$default-direction}: 16px;
    margin-bottom: 16px;
    margin-#{$opposite-direction}: 16px;
  }
  @include breakpoint(large) {
    width: 137px;
    height: 137px;
    margin-#{$default-direction}: auto;
  }

  .has-avatar & {
    float: $default-direction;
    margin-top: 0;
  }
}

.search-form-wrapper {
  margin-bottom: 2.5rem;

  .page-title {
    margin-bottom: 1rem;
    color: #303638;
    text-transform: capitalize;

    h1 {
      font-size: 2rem;
      margin-bottom: 0;

      a {
        color: $secondary-color;
      }
    }
  }
}

.search-form-main {
  &.searchbox {
    > div {
      display: flex;
      width: 100%;
      align-items: stretch;

      .form-item.form-type-textfield {
        flex: 1 1 0;
        height: auto;
        min-width: 0;
      }
    }
  }

  input.input-group-field {
    background-color: #fdfdfc;
  }

  .input-group-button {
    padding-#{$default-direction}: 1rem;
  }

  .button {
    font-family: "icomoon";
    font-size: 1.125rem;
    background-color: $secondary-color;
    width: 45px;
    padding: 0;
    color: $white;

    &:hover {
      background-color: $primary-color;
    }

    @include breakpoint(medium) {
      min-width: 5rem;
    }
  }
}

.search-meta {
  font-family: $font-secondary;
  font-weight: 500;
  font-size: $font-small2;

  .search-term {
    color: #5f7176;

    span {
      font-family: $font-secondary;
    }
  }

  .typo {
    font-family: $font-secondary;
    color: $black;

    span {
      color: $primary-color;
      text-transform: uppercase;
    }
  }
}

.posts-list {
  .article-item {
    font-family: $font-secondary;
    margin-bottom: 2rem;
  }

  .article-item-img {
    margin-bottom: 0.75rem;
  }
}

.article-item-meta {
  color: rgba($secondary-color, 0.6);

  a {
    color: rgba($primary-color, 0.8);

    &:hover {
      color: $black;
    }
  }

  .horoscopes-articles & {
    margin-bottom: 0.5rem;
  }
}

.entry-video-container {
  padding: 16px 0;
  background: $black;
  color: $white;
  overflow: hidden;
  margin-bottom: 24px;

  h1 {
    font-size: $font-large2;
    @include breakpoint(medium) {
      font-size: 36px;
    }
  }

  .entry-subtitle h2,
  .entry-meta-field span,
  .entry-meta-field time,
  .entry-meta-field:after {
    color: #dadfe1;
  }

  .author-twitter a,
  .entry-meta-comment a {
    color: #dadfe1;

    &:hover {
      color: $primary-color;
    }
  }

  .entry-meta-shares-count span {
    color: $primary-color;
  }
}

.branding {
  margin-top: -0.25rem;
  color: $dusty-gray;
  font-size: $font-small1;
  line-height: 1;

  a {
    color: inherit;

    &:hover {
      color: $primary-color;
    }
  }
}

.articles-list {
}

.show-for-printable-area {
  display: none;
}

@media print {
  /*.injected-block, .entry-promoted-stories, .share-block, .follow-block, .inline-gallery, .full-gallery, .responsive-embed, iframe {
    display: none;
  }*/
  /*
   header, footer, .sidebar-wrap-holder, .breadcrumbs-wrapper, .entry-tags, .entry-comments, .injected-block, .entry-promoted-stories, .share-block, .follow-block, .inline-gallery, .full-gallery, .responsive-embed, iframe {
     display: none;
   }
   .content-wrap-holder {
     width: 100%;
     max-width: none;
   }
   .entry-title h1 {
     font-size: 2.5rem;
   }
   .entry-meta {
     width: 100%;
     max-width: none;
   }
   .entry-header.has-avatar .entry-meta-wrapper > .grid-x > .cell.shrink {
     max-width: none;
   }
   */
}

.urdu-theme {
  * {
    word-spacing: -1px;
    letter-spacing: 0 !important;
  }
}

.horoscope-item {
  max-width: 320px;
  margin: 0 auto 2rem;

  a {
    color: $black;

    &:hover {
      color: $primary-color;
    }
  }
}

.horoscope-item-img {
  margin-bottom: 1rem;
  position: relative;
  padding: 1rem 1.5rem 0;
  @include breakpoint(480) {
    padding: 1rem 3rem 0;
  }

  img {
    border-radius: 50%;
    padding: 15px;
    border: silver solid 1px;
  }

  .daily-horoscopes & {
    a:after {
      content: "يومي";
    }
  }

  .weekly-horoscopes & {
    a:after {
      content: "أسبوعي";
    }
  }

  .monthly-horoscopes & {
    a:after {
      content: "شهري";
    }
  }

  .daily-horoscopes &,
  .weekly-horoscopes &,
  .monthly-horoscopes & {
    a {
      display: block;

      &:hover {
        opacity: 0.7;

        &:after {
          background-color: $primary-color;
        }
      }

      &:after {
        display: block;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        background-color: $black;
        color: $white;
        text-transform: uppercase;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 0.75rem;
        font-weight: bold;
        border-radius: 50%;
        @include breakpoint(medium) {
          font-size: $font-medium1;
          width: 80px;
          height: 80px;
          line-height: 80px;
        }
      }
    }
  }
}

.horoscope-item-meta {
  margin-bottom: 0.625rem;
  font-size: $font-small4;
  text-transform: uppercase;
}

.horoscope-item-name {
  margin-bottom: 0.625rem;
}

.horoscope-item-range {
  font-size: $font-small4;
}

.related-horoscope {
  border-top: 6px solid $black;
  padding-top: 1rem;
  margin-bottom: 2rem;

  img {
    max-width: 100px;
  }
}

/* ----- Live blog list styles ------ START ------ */

.RecentPostsWidget iframe[src*="jwplayer"] {
  width: 100%;
}

.liveblog-capsule {
  //margin: 0 auto 0.5rem;
  //padding: 0 1rem;
  @include breakpoint(large) {
    @include xy-grid-container();
    padding: 0;
  }
}

.liveblog-capsule .inner {
  font-family: $font-secondary;
  display: flex;
  align-items: center;
  padding: 20px 16px;
  background: #f7f7f7;
}

.liveblog-capsule.active .inner {
  background: #fffd38;
}

.liveblog-capsule span {
  display: flex;
  align-items: center;
  margin-#{$opposite-direction}: 8px;

  &:first-child {
    text-transform: uppercase;
    font-size: $font-large1;
    margin-#{$opposite-direction}: 16px;

    [dir="rtl"] & {
      font-size: $font-medium3;
    }

    &:before {
      content: " ";
      background: url(../img/icon-live-blog.png) no-repeat 50%;
      width: 20px;
      height: 15px;
      background-size: contain;
      margin-#{$opposite-direction}: 8px;
    }
  }
}

.liveblog-capsule time {
  font-size: $font-large1;

  [dir="rtl"] & {
    font-size: $font-medium3;
  }
}

body.article-liveblog {
  .entry-article-liveblog {
    background: #f7f7f7;
    border-top: 1px solid #dadfe1;
    padding-top: 18px;
    @media (min-width: 768px) {
      padding-top: 12px;
    }
  }
}

.liveblog .content {
  font-family: $font-secondary;
  padding: 20px;
  border: 1px solid #888;
  line-height: 1.5;

  img {
    width: 100%;
  }

  .twitter-widget {
    margin: 0 auto;
  }
}

.liveblog .liveblog-content .content,
.lb-items {
  position: relative;
}

@media (min-width: 768px) {
  .liveblog .content {
    //font-size: $font-medium2;
  }
}

.lb-items {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.lb-item {
  color: #281e1e;
}

.liveblog .liveblog-content:before {
  content: " ";
  border: 1px solid #888;
  position: absolute;
  margin: -24px 0 0 28px;
  height: 68px;

  [dir="rtl"] & {
    margin: -24px 28px 0 0;
  }
}

.liveblog .post-date {
  position: relative;
  font:
    700 17px Independent Sans,
    sans-serif;
  padding-#{$default-direction}: 20px;
  margin: 24px 0 24px 20px;

  [dir="rtl"] & {
    margin: 24px 20px 24px 0;
  }

  color: #888;
}

.liveblog .post-date:before {
  content: " ";
  border-radius: 50%;
  border: 2px solid #888;
  position: absolute;
  top: -1px;
  margin-#{$default-direction}: -20px;
  width: 19px;
  height: 19px;
  background: #fff;
}

.liveblog .post-date time {
  padding-#{$default-direction}: 8px;
}

.liveblog .post-footer {
  padding: 16px 20px;
  background: #fff;
  position: relative;
  border: 1px solid #888;
  border-width: 0 1px 1px;
}

.liveblog .post-footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-family: $font-secondary;
  font-size: $font-small3;
}

.liveblog .post-footer ul li.author-name {
  color: #ec1a2e;
  font-weight: 700;
  font-style: normal;
}

.liveblog .post-footer ul li:first-child {
  padding-#{$default-direction}: 0;
}

.liveblog .post-footer ul li {
  padding: 0 8px;
}

.liveblog .post-footer ul li:last-child {
  color: #888;
  position: relative;
}

.liveblog .post-footer ul li:last-child:before {
  content: "|";
  position: absolute;
  #{$default-direction}: -1px;
}

.highlights-wrapper {
  clear: both;
}

.highlights {
  color: #281e1e;
  border: 1px solid #cfcfcf;
  padding: 20px;
  margin-bottom: 16px;
  background: #fff;

  a {
    &:hover {
      color: $primary-color;
    }
  }

  ul {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .highlights h2 {
    font-size: $font-medium1;
  }
}

.highlights h2 {
  font-family: $font-secondary;
  font-size: 1.125rem;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.highlights > div :last-child {
  margin-bottom: 0;
}

.highlights li {
  position: relative;
  list-style: normal;
  padding-bottom: 26px;
  padding-#{$default-direction}: 31px;
  font-size: $font-small3;
  font-weight: 700;
  padding-top: 4px;
  list-style-type: none;

  &:before {
    content: " ";
    width: 19px;
    height: 19px;
    display: block;
    background: transparent url(../img/svg/white_arrow.svg) no-repeat;
    background-size: 19px;
    border-radius: 50%;
    padding-top: 4px;
    position: absolute;
    #{$default-direction}: 2px;
    top: 0;
    z-index: 2;

    [dir="rtl"] & {
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
      top: 2px;
    }
  }

  &:after {
    content: "";
    width: 2px;
    background: #dadfe1;
    height: 100%;
    position: absolute;
    #{$default-direction}: 11px;
    top: 0;
  }

  &:last-child:after {
    display: none;
  }
}

.highlights li a {
  color: inherit;
}

#live-list-update-button {
  width: 100%;
  background: #fffd38;
  border: 1px solid #888;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 15px 20px 10px;
  outline: none;
  color: #281e1e;
  cursor: pointer;
  font-family: $font-secondary;
  font-size: $font-medium1;

  &.active {
    display: flex;
  }

  #live-list-update-button .update-text {
    padding-#{$opposite-direction}: 10px;
    line-height: 1;
  }

  .icon-refresh {
    display: flex;
    margin-#{$default-direction}: 0.75rem;
  }
}

.liveblog .pagination-wrapper {
  overflow: hidden;
  position: relative;
  margin-bottom: 3rem;

  &:before {
    width: 2px;
    background: #888;
    content: " ";
    position: absolute;
    #{$default-direction}: 28px;
    height: 100%;
  }

  .pagination {
    font-family: $font-primary;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    margin: 30px 0 0;
    background: $white;
    border: 1px solid $medium-gray;
    position: relative;

    .prev {
      margin-#{$opposite-direction}: auto;
    }

    .next {
      margin-#{$default-direction}: auto;
    }

    .shortcut {
      display: flex;
      flex-wrap: wrap;

      > * {
        margin: 0 10px;
        display: flex;
        font:
          700 16px "Independent Sans",
          sans-serif;
      }
    }

    a {
      padding: 0;
      color: $primary-color;

      &:hover {
        background: transparent;
        color: $secondary-color;
      }
    }
  }
}

.icon-holder {
  &.abs-element {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 1;
    //width: 3rem;
    //height: 3rem;
    text-align: center;

    i {
      font-size: 2rem;
      padding: 1rem;
      background-color: #f9f9f9;
      border-radius: 10px;
      display: inline-block;
      vertical-align: middle;

      &.icon-facebook {
        background-color: $facebook;
        color: $white;
      }

      &.icon-twitter {
        background-color: $twitter;
        color: $white;
      }

      &.icon-youtube {
        background-color: $youtube;
        color: $white;
      }
    }
  }
}

/* ----- Live blog list styles ------ END ------ */

.subscribe-field-title {
  text-transform: uppercase;
  font-family: $font-secondary;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1;
}

.subscribe-blocks {
  .grid-items {
    height: 100%;
  }

  .article-item {
    height: calc(100% - 1rem);

    .article-item-img {
      height: 100%;
    }
  }

  .article-item-img a:after {
    z-index: 1;
  }
}

.card-item {
  border: 1px solid #e5e8ea;

  .card-section {
  }
}

.article-book {
  .entry-article-topper {
    max-width: 1240px;
  }

  .entry-header {
    text-align: center;
  }

  .entry-header.has-avatar .entry-subtitle {
    float: none;
    width: 100%;

    h2 {
      color: #888;
      font-size: $font-large2;
    }
  }

  .author-avatar {
    float: none;
    margin: 1rem auto;
    //width: 100%;
  }

  .entry-header.has-avatar .entry-meta-wrapper {
    float: none;
    width: 100%;
  }

  .entry-media-inner {
    padding: 0 2rem;
  }

  .entry-article-topper .caption-field {
    @include breakpoint(large) {
      position: absolute;
      #{$opposite-direction}: 0;
      width: calc((100% - 800px) / 2);
    }
  }
}

.first-letter {
  font-size: 52px;
  width: 52px;
  height: 52px;
  line-height: 1;
  font-weight: 300;
  margin-#{$opposite-direction}: 12px;
  margin-top: 6px;
  float: $default-direction;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    border-radius: 50%;
    border: 1px solid #222;
    width: 100%;
    height: 100%;
    top: 0;
    #{$default-direction}: 0;
  }
}

.campaign-area {
  .article-item-title {
    border-bottom: 1px solid #aaa;
    margin-bottom: 1rem;
  }

  p {
    font-size: $font-medium1;
    color: #222;
  }
}

.podcast-badge {
  ul {
    margin-#{$default-direction}: 0;

    li {
      margin-bottom: 10px;
      margin-right: 2px;
      margin-left: 2px;
      width: 47.5%;
      list-style: none;
      display: inline-block;
    }
  }
}

.podcast-list-channel {
  ul {
    margin-#{$default-direction}: 0;

    li {
      list-style: none;
      display: inline-block;

      img {
        width: 150px;
        @include breakpoint(medium) {
          width: 100px;
        }
        @include breakpoint(large) {
          width: 150px;
        }
      }
    }
  }
}

.podcast-list.has-separator .grid-x {
  border-bottom: 0.5px solid #bdbdbd;
  padding: 1rem 0;

  .article-img {
    margin-#{$opposite-direction}: 1rem;
    @include breakpoint(small only) {
      margin-bottom: 1rem;
    }
  }
}

.card-podcast {
  padding: 0.2rem;
  .podcast-black & {
    background-color: transparent;
    p {
      color: #8c8c8c;
    }
  }
}
.podcast-social {
  a {
    border: 1px solid #dddddd;
    background-color: $white;
    border-radius: 10px;
    padding: 5px;
    margin-#{$opposite-direction}: 5px;
    display: inline-flex;
  }
  img {
    width: 24px;
    height: 24px;
  }
}
// ============== <> =============== Add your custom styles before this line. ============== <> ===============

// slick carousel loading ---- START ------
.slick-loading {
  height: 400px;
  overflow: hidden;
  background: $white-smoke url(../img/ajax-loader.gif) no-repeat center center;

  > div {
    opacity: 0;
  }

  &.slick-initialized {
    height: auto;
    overflow: visible;
    background: none;
  }

  .slick-list {
    transition: height 250ms ease-in-out;
  }
}

// slick carousel loading ---- END ------

// limelight-player override styles ---- START ------

.limelight-player {
  width: 100% !important;
  padding-bottom: 56.34%;
  height: 0 !important;
  margin-bottom: 1.5rem;
  overflow: hidden;
  //padding-top: 1.5625rem;
}

.limelight-player .vjs-limelight-big-play {
  font-size: 12em !important;
}

// limelight-player override styles ---- END ------

/* -------- Final say ---------------- Start ------------------------*/

.media-object-for-small {
  @include breakpoint(small only) {
    margin-#{$default-direction}: 0;
    margin-#{$opposite-direction}: 0;
    .article-item {
      @include media-object-container;
      margin-bottom: 1.5rem;

      .article-item-img {
        @include media-object-section(0);
        @include flex-align-self(top);
        margin-#{$opposite-direction}: 0.75rem;

        img {
          max-width: 150px;
        }
      }

      .article-item-info {
        padding-#{$default-direction}: 1rem;
        @include media-object-section(0);
      }
    }
  }
}

.large-fluid-img {
  @include breakpoint(large) {
    img {
      width: 100%;
    }
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.no-border {
  border: 0;
}

.block-custom-bg {
  background-color: $smoke;
  color: $black;
}

.padding-horizontal-for-small {
  @include breakpoint(small only) {
    padding-#{$default-direction}: 1.25rem;
    padding-#{$opposite-direction}: 1.25rem;
  }
}

.with-padding {
  padding: 1rem;
}

.has-padding {
  padding: 1.5rem;
}

.vertical-padd {
  padding: 1rem 0;
}

.ft-sz-15 {
  font-size: rem-calc(15);
}

.ft-sz-18 {
  font-size: rem-calc(18);
}

.ft-sz-28 {
  font-size: rem-calc(28);
  @include breakpoint(small only) {
    font-size: rem-calc(20);
  }
}

@include breakpoint(large) {
  .row .large-column.row.row,
  .row .row.row.large-columns {
    margin-#{$default-direction}: 0;
    margin-#{$opposite-direction}: 0;
    padding-#{$default-direction}: 0;
    padding-#{$opposite-direction}: 0;
  }
}

@media only screen and (max-width: 30em) {
  .xsmall-full-width > li,
  .xsmall-full-width.columns {
    list-style: outside none none;
    width: 100%;
  }
}

.top-padding-1 {
  padding-top: 1rem;
}

.top-padding-2 {
  padding-top: 2rem;
}

.see-more-link {
  a {
    font-family: $font-secondary;
    font-size: $font-medium1;
    color: $primary-color;

    &:hover {
      color: $dark-gray;
    }
  }
}

.gray-font-color {
  color: $gray;
}

.primary-font-color {
  color: $primary-color;
}

.emperor-font-color {
  color: $emperor;
  @at-root {
    h3#{&},
    h4#{&},
    h5#{&},
    h6#{&} {
      a {
        color: inherit;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.border-gray-around {
  border: 1px solid $light-gray;
}

.thick-border-gray-around {
  border: 2px solid $light-gray;
}

.thicker-border-gray-around {
  border: 4px solid $light-gray;
}

.gray-border-bottom {
  border-bottom: 1px solid rgba(#b3b3b3, 0.3);
}

.gray-border-top {
  border-top: 1px solid rgba(#b3b3b3, 0.3);
  padding-top: 0.5rem;
}

.texture-pink-bg {
  background: transparent url(" . . /img/ texture-pink . jpg ") repeat center center;

  h2,
  p,
  a,
  div {
    color: $white;
  }
}

.white-bg {
  background-color: $white;
}

.light-gray-bg {
  background-color: $light-gray;

  &.abs-element {
    background-color: $light-gray;
  }
}

.white-smoke-bg {
  background-color: $white-smoke;
  color: $black;
}

.white-smoke-opacity-bg {
  background-color: rgba($white-smoke, 0.6);
  color: $black;
}

.top-portion-black-bg {
  &:before {
    position: absolute;
    z-index: -1;
    top: 0;
    #{$default-direction}: 0;
    #{$opposite-direction}: 0;
    width: 100%;
    height: 55%;
    content: "";
    background-color: #1c1c1c;
  }
}

.bottom-portion-smoke-bg {
  &:before {
    position: absolute;
    z-index: -1;
    bottom: 0;
    #{$default-direction}: 0;
    #{$opposite-direction}: 0;
    width: 100%;
    height: 30%;
    content: "";
    background-color: $smoke;
  }
}

.white-to-smoke-gradient-bg {
  background: linear-gradient(to bottom, $white, $smoke);
}

.white-to-light-gray-gradient-bg {
  background: linear-gradient(to bottom, $white, $light-gray);
}

.smoke-to-white-gradient-bg {
  background: linear-gradient(to bottom, $smoke, $white);
}

.light-gray-to-white-gradient-bg {
  background: linear-gradient(to bottom, $light-gray 70%, $white);
}

.gradient-bg {
  position: relative;
  background: linear-gradient(90deg, rgba(247, 247, 247, 0.1) 33%, rgba(255, 255, 255, 0.6) 0);

  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 783px;
    bottom: 0;
    #{$opposite-direction}: 0;
    #{$default-direction}: 0;
    z-index: -1;
    //background: transparent url(" . . /img/ texture2 . png ") no-repeat bottom center;
  }
}

/*.texture-bg {
  background-image: url(" . . /img/ texture2 . png ");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}*/

.gray-bg {
  background-color: $white-smoke;
  color: $secondary-color;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;

    a {
      color: inherit;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.dark-gray-bg {
  background-color: #252525;
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;

    a {
      color: inherit;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.button.hollow,
.pager--infinite-scroll li a {
  border-width: 1px;
}

label {
  span.required {
    color: red;
    font-weight: bold;
  }
}

.last-no-margin {
  @include breakpoint(medium) {
    article:last-child {
      margin-bottom: 0 !important;

      h4,
      h5,
      h6 {
        margin-bottom: 0;
      }
    }
  }
}

.maxWidth-medium,
.maxWidth-small,
.maxWidth-xsmall {
  float: none;
  margin-#{$default-direction}: auto;
  margin-#{$opposite-direction}: auto;
}

.maxWidth-medium {
  max-width: 800px;
}

.maxWidth-small {
  max-width: 670px;
}

.maxWidth-xsmall {
  max-width: 360px;
}

.grid-collapse {
  @include breakpoint(large) {
    @include grid-column-collapse;
  }
}

.small-grid-collapse {
  @include breakpoint(small only) {
    @include grid-column-collapse;
    .article-item-info,
    .article-item-overbox-inner {
      padding: 0 1.25rem;
    }
  }
  @media screen and #{breakpoint(390)} and #{breakpoint(medium down)} {
    .grid-items {
      .article-item-info,
      .article-item-overbox-inner {
        padding: 0;
      }
    }
  }

  .padding-horizontal-for-small {
    .block-title.style-v3 span,
    .section-title.style-v3 span,
    .page-title.style-v3 span {
      padding: 0;
    }

    .article-item-overbox-inner {
      padding: 0;
    }
  }
}

.centered-column {
  @include grid-column-position(center);
}

.newsletter-area {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  background: $white;
  position: relative;
  margin: 0;
  color: $black;

  input {
    border-radius: 6px !important;
  }

  p {
    font-size: $font-small1;
    margin-bottom: 5px;
  }

  input[type=" submit "] {
    border: 0;

    &:hover {
      background-color: $white;
      color: $primary-color;
    }
  }

  .input-group-field {
    height: 2.6rem;
    font-size: $font-small3;
    border: 0;
  }
}

.newsletter-area-intro {
  h3 {
    margin: 0;
  }

  span {
    display: block;

    &:first-child {
      font-size: rem-calc(30);
      color: $primary-color;
    }
  }
}

.mc_embed_signup div#mce-responses {
  float: $default-direction;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}

.mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: $default-direction;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}

.mc_embed_signup #mce-error-response {
  display: none;
}

.mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}

.mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-#{$default-direction}: 1.05em;
  text-align: $default-direction;
  padding: 0.5em 0;
}

.banner-img {
  margin-bottom: 1rem;
}

.schedule {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  background: #f5f5f5;
  padding: 1rem;
  margin: 1rem 0;
  font-family: $font-secondary;

  .media-object {
    margin-bottom: 0;
  }

  .author-name {
    color: inherit;
    font-style: normal;
  }

  .author-live {
    font-size: $font-large1;
    font-weight: 700;
  }

  ul {
    @include breakpoint(small only) {
      margin-#{$default-direction}: 0;
      margin-top: 1rem;
    }

    li {
      list-style: none;
    }
  }
}

.news-item {
  padding: 0 1.5rem;
}

.stations-list {
  ul {
    margin-#{$default-direction}: 0;

    li {
      list-style: none;
      display: inline-block;
      margin: 0 0.2rem;
      @include breakpoint(large) {
        margin: 0 0.5rem;
      }
    }
  }
}

/* Drupal admin styles ----- START -----*/
#admin-menu {
  z-index: 10000001;
}

#overlay-container,
.overlay-modal-background,
.overlay-element {
  z-index: 9999999;
}

.overlay-active {
  z-index: 10000000;
}

div#toolbar a#edit-shortcuts {
  position: relative;
}

html.overlay-open body {
  position: relative;
}

.password-strength,
div.password-confirm {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
}

.drupal-tabs ul.tabs {
  list-style: none;
  margin-#{$opposite-direction}: 0;

  li {
    background-color: #f8f8f8;
    border-radius: 5px;
    display: inline-block;
    margin: 0 5px 8px;
    padding: 5px 15px;

    &:hover,
    &.active {
      background: $primary-color;

      a {
        color: white;
      }
    }
  }
}

.page-taxonomy-term .article-teaser .article-category {
  display: none;
}

.load-more ul li {
  list-style: none;
}

.pager--infinite-scroll {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  text-align: center;

  li a {
    @include button(false, $gray, $primary-color, $secondary-color, hollow);
    @include button-style($gray, transparent, $secondary-color);
    font-family: $font-primary;
    text-transform: uppercase;
    width: 30%;
  }
}

.admin-menu {
  ul.tabs.primary {
    list-style: none;

    li {
      display: inline-block;
      margin: 0 5px;
      padding: 5px 15px;

      &:hover,
      &.active {
        background: $primary-color;

        a {
          color: white;
        }
      }
    }
  }
}

.element-invisible {
  display: none;
}

/* Drupal admin styles ----- END -----*/

/* ------- Socials Btns Styles ------- START -------- */

.socials-area {
  header &,
  #sticky-header & {
    position: relative;
    display: inline-block;
    z-index: 1;
  }

  .canvas-panel & {
    padding: 1.5rem 0;
  }

  footer & {
    margin-bottom: 0;
    line-height: 18px;
    font-weight: 600;

    h6 {
      font-size: $font-small3;
    }
  }
}

.social-btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: $silver;
  font-size: 1.25rem;
  margin: 0 8px 0 0;
  line-height: 1;

  [dir="rtl"] & {
    margin: 0 0 0 8px;
  }

  &:hover {
    color: $black;
    transition: color 0.25s linear;
  }

  .share_tools & {
    margin: 0 0 0 4px;
  }

  .entry-meta-wrapper &.round {
    background-color: $medium-gray;
    color: $white;

    &:hover,
    &:focus {
      background-color: $black;
    }
  }

  &.round {
    width: 34px;
    height: 34px;
    line-height: 38px;
    font-size: $font-medium1;
    background-color: $primary-color;
    vertical-align: middle;
    color: $white;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // &:hover,
    // &:focus {
    //   color: $white;
    //   background-color: $primary-color !important;
    // }
  }

  .canvas-panel & {
    border-radius: 50%;
    background: #1d1d1b;
    color: $white;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
  }

  img {
    width: 17px;
  }
}

.socials-block {
  padding: 2rem 0;
  text-align: center;

  a {
    margin: 0 0.25rem;
    font-size: 1.25rem;
    padding: 0.75rem;
    //width: 35px !important;
    //height: 35px;
    background: $white;
    //line-height: 39px !important;
    &:hover,
    &:active,
    &:focus {
      background-color: $primary-color !important;
      color: $white;
    }

    i {
      vertical-align: middle;
    }
  }
}

.envelope-btn {
  color: $envelope;
}

.envelope-btn-hover {
  &:hover {
    color: $envelope !important;
  }
}

.rss-btn {
  color: $rss;
}

.rss-btn-hover {
  &:hover {
    color: $rss !important;
  }
}

.youtube-btn {
  color: $youtube;
}

.youtube-btn-hover {
  &:hover {
    color: $youtube !important;
  }
}

.google-plus-btn {
  color: $gplus;
}

.google-plus-btn-hover {
  &:hover {
    color: $gplus !important;
  }
}

.twitter-btn {
  color: $twitter;
}

.twitter-btn-hover {
  &:hover {
    color: $twitter !important;
  }
}

.facebook-btn {
  color: $facebook;
}

.facebook-btn-hover {
  &:hover {
    color: $facebook !important;
  }
}

.whatsapp-btn {
  color: $whatsapp;
}

.whatsapp-btn-hover {
  &:hover {
    color: $whatsapp !important;
  }
}

.instagram-btn {
  color: $instagram;
}

.instagram-btn-hover {
  &:hover {
    color: $instagram !important;
  }
}

.snapchat-btn {
  color: $snapchat;
  text-shadow: 0 0 2px $medium-gray;
}

.telegram-btn {
  color: $telegram;
}

.linkedin-btn {
  color: $linkedin;
}

.pinterest-btn {
  color: $pinterest;
}

.socials-btns-big {
  margin-bottom: 1.5rem;

  .social-btn {
    color: $white;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.75rem;
    margin: 0 10px;
    transition: all 0.25s ease 0s;

    &:hover {
      background-color: $primary-color;
    }
  }
}

.envelope-btn-bg {
  &:hover {
    background-color: $envelope !important;
    color: $white;
  }
}

.rss-btn-bg {
  &:hover {
    background-color: $rss !important;
    color: $white;
  }
}

.youtube-btn-bg {
  &:hover {
    background-color: $youtube !important;
    color: $white;
  }
}

.youtube-btn-bg-hover {
  &:hover {
    background-color: $youtube !important;
    color: $white;
  }
}

.gplus-btn-bg {
  &:hover {
    background-color: $gplus !important;
    color: $white;
  }
}

.twitter-btn-bg {
  // background-color: $twitter !important;
  // color: $white;
  &:hover {
    background-color: $black !important;
    color: $white;
  }
}

.twitter-btn-bg-hover {
  &:hover {
    background-color: $twitter !important;
    color: $white;
  }
}

.facebook-btn-bg {
  // background-color: $facebook;
  // color: $white;
  &:hover {
    background-color: $facebook !important;
    color: $white;
  }
}

.facebook-btn-bg-hover {
  &:hover {
    background-color: $facebook !important;
    color: $white;
  }
}

.instagram-btn-bg {
  background-color: $instagram !important;
  color: $white;
}

.instagram-btn-bg-hover {
  &:hover {
    background-color: $instagram !important;
    color: $white;
  }
}

.google-plus-btn-bg-hover {
  &:hover {
    background-color: $gplus !important;
    color: $white;
  }
}

.snapchat-btn-bg {
  background-color: $snapchat !important;
  color: $white;
  &:hover {
    background-color: $snapchat;
    color: $white;
  }
}

.telegram-btn-bg {
  background-color: $telegram !important;
  color: $white;
  .icon-telegram1:before {
    color: #ffffff;
  }
  &:hover {
    background-color: $telegram;
    color: $white;
  }
}

.linkedin-btn-bg {
  &:hover {
    background-color: $linkedin !important;
    color: $white;
  }
}

.pinterest-btn-bg {
  &:hover {
    background-color: $pinterest !important;
    color: $white;
  }
}

.whatsapp-btn-bg {
  &:hover {
    background-color: $whatsapp !important;
    color: $white;
  }
}

/* ------- Socials Btns Styles ------- END -------- */

#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: $primary-color;
  opecity: 0.7;
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;

  z-index: 10;
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 0;
  top: 13px;
  font-size: $font-medium4;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
  color: #fff;
  top: 5px;
}

/* -------- Final say ---------------- End ------------------------*/

.article-v2,
.article-v2 * {
  font-family: Yekan, "Yekan Numbers" !important;
}

.article-v3,
.article-v3 * {
  font-family: "Noto Naskh Arabic" !important;
}

.persian-theme {
  .entry-media {
    .caption-field {
      p {
        font-size: 1rem;
      }
    }
  }
}

.arabic-theme {
  .nav-container {
    > .menu {
      > li.menu-6921 {
        a {
          text-align: center;
          padding: 8px 16px;
          border: 3px solid $primary-color;

          &:hover {
            color: $primary-color;
          }
        }
      }

      > li.active {
        a {
          color: $white;

          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}

.indy-tv {
  main {
    padding: 0.5rem 0 0;
  }

  .bg-gray {
    background-color: #222;
    color: white;

    .article-item-title {
      a {
        color: white;
      }
    }
  }

  .logo-tv {
    a {
      margin: 0 auto;
      height: 107px;
      background-size: 38%;
      border-bottom: 0.5px solid #bdbdbd;
      display: block;
      text-indent: -999em;
      overflow: hidden;
      background-image: url(../img/logotv.png?ffd);
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }

  .currentVideo {
    > h6 {
      span {
        font-weight: 700;
      }

      font-weight: 300;
    }
  }

  .upNextVideos {
    > h6 {
      font-weight: 700;
      padding: 30px 0 10px;
    }
  }

  .media-video {
    .article-item-img-tv {
      position: relative;

      &:before {
        width: 23px;
        height: 23px;
        line-height: 23px;
        font-size: $font-medium1;
        background: rgba(236, 26, 46, 0.5);
        color: #ffffff;
        border-radius: 50%;
        cursor: pointer;
        text-align: center;
        content: "\e90b";
        position: absolute;
        font-family: "icomoon";

        z-index: 1;
        bottom: 0;
        text-indent: -3px;
        margin: 0.5rem;
      }

      p {
        &.duration {
          position: absolute;
          bottom: 0;
          #{$opposite-direction}: 0;
          #{$default-direction}: auto;
          border-radius: 2px;
          margin: 0.5rem 0.3px;
          color: #fff;
          background: #222;
          font-size: $font-small1;
          line-height: 13px;
          padding: 2px 4px;
        }
      }
    }
  }

  .objects-list {
    &.has-separator {
      .object-item {
        border-bottom: 0.5px solid #bdbdbd;

        .media-object {
          &.media-video {
            .media-object-img,
            .article-item-img-tv {
              &:before {
                width: 23px;
                height: 23px;
                line-height: 23px;
                font-size: $font-medium1;
                background: rgba(236, 26, 46, 0.5);
              }

              p {
                &.duration {
                  position: absolute;
                  bottom: 0;
                  #{$opposite-direction}: 0;
                  border-radius: 2px;
                  margin: 0.5rem 0.3px;
                  color: #fff;
                  background: #222;
                  font-size: $font-small1;
                  line-height: 13px;
                  padding: 2px 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .tv-slider {
    .article-item {
      margin-#{$opposite-direction}: 15px;
    }

    .slick-prev,
    .slick-next {
      top: 38%;
    }

    .slick-next {
      #{$opposite-direction}: 25px;
      #{$default-direction}: auto;
    }
  }

  .playlistSlabAndTitle {
    border-bottom: 0.5px solid #bdbdbd;

    > h4 {
      padding-#{$default-direction}: 40px;
      background-image: url(../img/smalltvlogo.png?sss);
      background-repeat: no-repeat;
      background-position: #{$default-direction};
      background-size: 30px 30px;

      a {
        font-weight: 300;
        color: #000;
      }
    }
  }
}

/*------ Podcast black---*/

.podcast-items {
  color: $medium-gray;
  padding-bottom: 2rem;

  .items {
    border: 1px solid #e5e8ea;
    .podcast-black & {
      border-color: #636363;
    }
    margin: 2rem 0;
    .article-item-img {
      position: relative;

      .tagged {
        padding: 0.5rem;
        position: absolute;
        bottom: 10px;
        right: 15px;
        width: 90px;
        z-index: 1;

        a {
          box-shadow:
            -5px 0px 0 7px #00b5f6,
            5px 0px 0 7px #00b5f6,
            0 0 0 7px #00b5f6;
          box-decoration-break: clone;
          background-color: #00b5f6;
          color: #fff;
          line-height: 2;
        }
      }

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0.5;
      }

      .align-center-middle {
        color: $white;
        font-size: 2rem;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 42%;
        line-height: 1.5;

        a {
          color: $white;
        }

        &:first-line,
        .small-link {
          font-size: $font-medium3;
          display: block;
        }
      }
    }
  }

  .info {
    margin: 0 1rem;
    position: relative;
    height: 100%;

    .caption {
      margin: 1rem 0;
      font-size: 1.1rem;
      line-height: 1.8;
    }

    .actions {
      position: absolute;
      bottom: 30px;
      a {
        img {
          max-width: 145px;
          height: 37px;
        }
      }
    }
  }
}
.podcast-black {
  .pdt0 {
    padding-top: 0 !important;
  }

  .mb0 {
    margin-bottom: 0 !important;
  }

  .breadcrumbs {
    li {
      a {
        color: $medium-gray;
      }

      &:not(:last-child)::after {
        color: $medium-gray;
      }

      .active {
        color: #ec1a2e;
      }
    }
  }
  main {
    background-color: $black;
    margin-bottom: 0;
  }

  #main-footer {
    margin-top: 0;

    .footer-menu {
      a {
        color: $secondary-color;
        &:hover {
          color: #ec1a2e;
        }
      }
    }
  }

  h3,
  h4,
  a {
    color: $white;

    &:hover {
      color: $primary-color;
    }
  }

  .section-title {
    color: $white;

    a:not(.see-all) {
      color: $white;

      &:hover {
        color: $primary-color;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .actions {
      position: relative !important;
      bottom: 0 !important;
      margin-bottom: 10px;

      a {
        margin: 10px 0;
        display: inline-block;
      }
    }
  }
}

/////

.date1 {
  font-size: 0.8rem;
  color: $dark-gray;
}

.headtxt {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: "Independent Sans";
}

.imgComment {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.MainHeroImg {
  display: flex;
  width: 50%;
  justify-content: center;
  padding: 0;
  margin-bottom: 1rem;
  @include breakpoint(small only) {
    width: 100%;
  }
  @include breakpoint(medium only) {
    height: 400px;
  }

  &.grid-x {
    width: 100%;
  }
}

.four-articles1 .article-item-content.OverText {
  position: absolute;

  @include breakpoint(small only) {
    position: inherit;
  }
}

.MainHeroSmall {
  @include breakpoint(small only) {
    display: none;
  }
}

/////

.form-registration {
  .form-registration-img {
    margin-left: 11rem;
    display: flex;

    .form-registration-img-caption {
      position: absolute;
      padding: 0.5rem;
      text-align: center;
      width: 100%;
      bottom: 0;
      background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(1, rgba($white, 0.3)),
        /* Top */ color-stop(0, rgba($white, 1)) /* Bottom */
      );
    }
  }
}

.form-registration-group {
  padding: 0px;
  display: flex;

  .form-registration-input {
    &:focus {
      border: 2px solid;
      box-shadow: 0 0 5px;
    }
  }

  .form-registration-submit-button {
    text-transform: uppercase;
    margin-bottom: 0;
    @include button(true, $primary-color, auto, auto, solid);

    padding: 0 10px;
    margin-left: 12px;
    border: none;
    border-radius: 4px;
    flex-shrink: 0;
    font-size: $font-small2;
    line-height: 20px;
    background: #999596;
    color: #fff;
    appearance: none;
    text-transform: uppercase;
    transition: color 0.4s linear;
    cursor: pointer;
    width: 30%;
  }

  .form-registration-social-button {
    text-transform: uppercase;
    font-size: $font-medium1;
    @include button(true, auto, hollow);
    border-radius: 2px;
    width: 57%;

    .fa {
      vertical-align: text-top;
      margin-right: 0.5rem;
    }
  }

  .form-registration-member-signin {
    text-align: center;
    font-weight: bold;
    font-size: $font-small3;

    a {
      margin-left: 0.25rem;
    }
  }

  .form-registration-terms {
    text-align: center;
    font-size: $font-small1;

    a:first-child {
      margin-right: 0.25rem;
    }

    a:last-child {
      margin-left: 0.25rem;
    }
  }

  .or-divider {
    position: relative;
    margin: 1.5rem 0px;
    font-size: $font-small3;
    font-weight: 600;
    width: 100%;
    height: 0px;
    border: 1px solid $light-gray;

    span {
      color: $dark-gray;
      @include horizontal-center;
      top: -12px;

      padding: 0 8px;
    }
  }
}
.imglogo {
  // margin-left: -11rem;
  // margin-right: 9rem;
  // width: 20%;
  height: 15%;
}

.button.hollow.button {
  margin-right: 1rem;
  /* border-radius: 6px; */
  border-color: #337e81;
  border: 2px px solid #337e81;
  border-radius: 6px;
  color: #337e81;
  /* font-weight: bold; */
  border: 2px solid;
  padding-left: 10px;
  font-weight: bold;
  /* align-items: center; */
  /* justify-content: center; */
  box-sizing: border-box;
  font-size: medium;
  border: 2px solid #337e81;
  border-radius: 6px;
  color: #337e81;
  /* display: none; */
  font-family:
    Indy Sans,
    sans-serif;
  /* font-weight: 500; */
  /* margin-right: 16px; */
  margin-left: 0;
  width: 102px;
  height: 40px;
  line-height: 16px;
  /* padding-top: 2px;*/
  [dir="rtl"] & {
    margin-left: 1rem;
  }
}

///
.header1 {
  //padding: 0 0 12px 12px;
  margin: 0 auto;
  // border: 1px solid #D3D3D3;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  grid-gap: 2px;
}

.header__text {
  padding-top: 12px;
  flex: 1;
  margin-bottom: -91px;
}

.svg {
  flex-shrink: 0;
  display: block;
}
.header__title {
  margin-top: 16px;
  font-family: "Independent Serif";
  font-size: $font-large1;
  font-weight: 700;
  line-height: 18px;
  color: #222222;
}

.image-container {
  width: 135px;
  height: 135px;
  margin-left: 6px;
  @include breakpoint(small only) {
    display: none;
  }
}

.section-newsletter-signup__form {
  position: relative;
  margin-top: 20px;
  padding-right: 12px;
}
.section-newsletter-signup__input {
  display: flex;
  @include breakpoint(small only) {
    display: grid;
    margin-bottom: 1rem;
  }
}
.input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-grow: 3;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: solid 1px #bdbdbd;
  padding-left: 12px;
  padding-right: 12px;
  color: #4e4e4e;
  outline: none;
  font-size: $font-medium1;
}
input#offers {
  height: 30px;
  width: 30px;
}
.submit-button {
  background-color: #bdbdbd;
  padding: 0 10px;
  margin-left: 12px;
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: $font-small2;
  line-height: 20px;
  background: #bdbdbd;
  color: #fff;
  appearance: none;
  text-transform: uppercase;
  transition: color 0.4s linear;
  cursor: pointer;
  width: 33%;
  height: 37px;
}

.offer {
  display: flex;
  align-items: center;
  margin-top: 0px;
  font-family: "Independent Sans", sans-serif;
  font-size: $font-xxsmall;
  line-height: 12px;
  color: #4e4e4e;
}
.offers-label .logo {
  display: none;
}
.offers-label {
  display: block;
  margin: 0;
  font-size: $font-xsmall;
  font-weight: normal;
  line-height: 1.8;
  margin-left: 0.5rem;
  color: #000000;
}

.section-newsletter-signup {
  padding: 0 0 12px 12px;
  margin: 0 auto;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 1rem;
}

.checkbox {
  // width: 24px;
  height: 33px;

  position: relative;
  flex-shrink: 0;
}

.form-registration-submit-button {
  padding: 0 10px;
  margin-left: 12px;
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: $font-small2;
  line-height: 20px;
  background: #bdbdbd;
  color: #fff;
  appearance: none;
  text-transform: uppercase;
  transition: color 0.4s linear;
  cursor: pointer;
  height: 38px;
  @include breakpoint(small only) {
    margin-left: 0px;
  }
}

input#email-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-grow: 3;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: solid 1px #bdbdbd;
  padding-left: 12px;
  padding-right: 12px;
  color: #4e4e4e;
  outline: none;
  font-size: $font-medium1;
}

label {
  font-family: "Independent Sans", sans-serif;
  font-size: $font-xxsmall;
  line-height: 12px;
  color: #4e4e4e;
  a {
    text-decoration: underline;
    color: #4e4e4e;
  }
}

.cell.medium-6.large-6 {
  @include breakpoint(small only) {
    width: 110%;
  }
}

.cell.small-6.medium-3 {
  margin-bottom: 0.5rem;
}

// ============== START: article audio player ===================
.audio-player {
  margin-bottom: 1rem;
  .podcast-floater & {
    margin-bottom: 0;
  }
  audio {
    width: 100%;
  }
}
.podcast-floater {
  background-color: $white;
  z-index: 99;
  padding: 0;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  @include breakpoint(large) {
    padding: 1rem 4rem;
  }
  .btn-share-wrap {
    .btn-share {
      margin: 0 10px;
    }
  }
  .close-btn {
    cursor: pointer;
    @include breakpoint(small only) {
      padding-top: 10px;
      padding-right: 13px;
      padding-left: 0;
      padding-bottom: 10px;
      border-right: 1px solid #e0e0e0;
      color: #90a3a2;
      font-size: $font-small1;
    }
  }
}

.podcast-floater-item {
  @include breakpoint(small only) {
    padding: 10px 0;
  }
  a {
    color: #281e1e;
  }
  img {
    width: 50px;
    @include breakpoint(small only) {
      display: none;
    }
  }
  h6 {
    margin-bottom: 0;
    @include breakpoint(small only) {
      font-size: $font-xsmall;
    }
  }
}

// ============== END: article audio player ===================

.page-logo {
  margin: 0 auto;
  border-bottom: 0.5px solid #bdbdbd;
  padding-bottom: 2rem;
  justify-content: center;

  img {
    width: 300px;
    @include breakpoint(large) {
      width: 400px;
    }
  }
}

// ============== START: Subscribe Box  ===================
.subscribe-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto 16px;

  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    max-width: 740px;
    padding: 40px 0;
  }

  &__img {
    display: flex;
    justify-content: center;
    img {
      width: 80px;
      @include breakpoint(medium) {
        width: 180px;
      }
    }
  }
  &__desc {
    text-align: center;
    @include breakpoint(medium) {
      text-align: start;
      max-width: 440px;
    }
    h2 {
      color: #ee3e3e;
      font-size: 37px;
      margin: 0;
      @include breakpoint(medium) {
        font-size: 45px;
      }
    }
    p {
      font-size: 12px;
      @include breakpoint(medium) {
        font-size: 16px;
        margin-bottom: 1.5rem;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    @include breakpoint(medium) {
      flex-direction: row;
      gap: 10px;
    }

    input[type="text"],
    input[type="text"] {
      background: #efefef;
      border-radius: 20px;
      width: 181px;
      padding: 5px 15px;
      border: 0;
      box-shadow: none;
      height: auto;
      font-size: 14px;
      margin: 0;
      @include breakpoint(medium) {
        font-size: 15px;
        padding: 7px 15px;
        width: 300px;
      }
    }

    button,
    input[type="submit"] {
      border: 0;
      background: #ee3e3e;
      border-radius: 15px;
      color: #ffffff;
      font-weight: bold;
      padding: 5px 20px;
      font-size: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background: rgba(0, 0, 0, 0.9);
        transition: all 0.3s ease;
      }
      @include breakpoint(medium) {
        font-size: 14px;
        padding: 7px 20px;
      }
    }
  }
}

// ============== END: Subscribe Box  ===================
