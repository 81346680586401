@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';

    @if $global-flexbox {
      flex-basis: 0;
      order: 1;
    }
  }

  &::after {
    clear: both;
  }
}
