#notfound {
  position: relative;
  min-height: 450px;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 710px;
  width: 100%;
  padding-#{$default-direction}: 190px;
  line-height: 1.4;
  font-family: $font-secondary;
}

.notfound .input-group {
    margin-bottom: 0;
}

.notfound .notfound-404 {
  position: absolute;
  #{$default-direction}: 0;
  top: 0;
  width: 150px;
  height: 150px;
}

.notfound .notfound-404 h1 {
  color: $primary-color;
  font-size: 150px;
  //letter-spacing: 15.5px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width:100%
}

.notfound h2 {
  color: #292929;
  font-size: 28px;
  text-transform: uppercase;
  //letter-spacing: 2.5px;
  margin-top: 0;
}

.notfound p {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
}

.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #fff;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #292929;
  -webkit-box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #fff;
  background-color: $primary-color;
}


@include breakpoint(small down) {
  .notfound {
    text-align: center;
  }
  .notfound .notfound-404 {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }
  .notfound {
    padding-#{$default-direction}: 15px;
    padding-#{$opposite-direction}: 15px;
  }
}
