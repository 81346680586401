$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;


$icon-email: "\e929";
$icon-fb: "\e924";
$icon-home-2: "\e925";
$icon-print: "\e926";
$icon-whatsapp2: "\e927";
$icon-x: "\e92a";
$icon-twitter-x: "\e922";
$icon-printer1: "\e923";
$icon-home: "\e921";
$icon-instagram1: "\e91f";
$icon-snapchat-ghost: "\e916";
$icon-youtube: "\e917";
$icon-camera: "\e912";
$icon-whatsapp: "\e928";
$icon-sphere: "\e920";
$icon-telegram: "\e91d";
$icon-printer: "\e91c";
$icon-linkedin: "\e918";
$icon-google-plus: "\e919";
$icon-pinterest: "\e91a";
$icon-instagram: "\e91b";
$icon-whatsapp1: "\e915";
$icon-dislike: "\e910";
$icon-like: "\e911";
$icon-envelope: "\e913";
$icon-comment: "\e914";
$icon-uk-flag: "\e615";
$icon-us-flag: "\e616";
$icon-google: "\e900";
$icon-twitter: "\e901";
$icon-facebook: "\e902";
$icon-arrow-down2: "\e903";
$icon-arrow-left2: "\e904";
$icon-arrow-right2: "\e905";
$icon-arrow-up2: "\e906";
$icon-arrow-down: "\e907";
$icon-arrow-left: "\e908";
$icon-arrow-up: "\e909";
$icon-arrow-right: "\e90a";
$icon-play: "\e90b";
$icon-close: "\e90c";
$icon-user: "\e90d";
$icon-hamburger: "\e90e";
$icon-search: "\e90f";
$icon-sound-wave: "\e91e";

