$font-path: "../fonts";

@import 'icomoon';

@font-face {
  font-family: 'Yekan Numbers';
  src: url('#{$font-path}/YekanNumbers-Regular.woff2') format('woff2'),
  url('#{$font-path}/YekanNumbers-Regular.woff') format('woff'),
  url('#{$font-path}/YekanNumbers-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face { 
  font-family: Vazir; 
  src: url('#{$font-path}/Vazir-Regular.eot'); 
  src: url('#{$font-path}/Vazir-Regular.eot?#iefix') format('embedded-opentype'), 
       url('#{$font-path}/Vazir-Regular.woff2') format('woff2'),
       url('#{$font-path}/Vazir-Regular.woff') format('woff'),
       url('#{$font-path}/Vazir-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {

  font-family: Vazir;
  src: url('#{$font-path}/Vazir-Bold.eot');
  src: url('#{$font-path}/Vazir-Bold.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/Vazir-Bold.woff2') format('woff2'),
       url('#{$font-path}/Vazir-Bold.woff') format('woff'),
       url('#{$font-path}/Vazir-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {

  font-family: Vazir;
  src: url('#{$font-path}/Vazir-Black.eot');
  src: url('#{$font-path}/Vazir-Black.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/Vazir-Black.woff2') format('woff2'),
       url('#{$font-path}/Vazir-Black.woff') format('woff'),
       url('#{$font-path}/Vazir-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url('#{$font-path}/Vazir-Medium.eot');
  src: url('#{$font-path}/Vazir-Medium.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/Vazir-Medium.woff2') format('woff2'),
       url('#{$font-path}/Vazir-Medium.woff') format('woff'),
       url('#{$font-path}/Vazir-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url('#{$font-path}/Vazir-Light.eot');
  src: url('#{$font-path}/Vazir-Light.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/Vazir-Light.woff2') format('woff2'),
       url('#{$font-path}/Vazir-Light.woff') format('woff'),
       url('#{$font-path}/Vazir-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url('#{$font-path}/Vazir-Thin.eot');
  src: url('#{$font-path}/Vazir-Thin.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/Vazir-Thin.woff2') format('woff2'),
       url('#{$font-path}/Vazir-Thin.woff') format('woff'),
       url('#{$font-path}/Vazir-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* arabic */
@font-face {
  font-family: 'Noto Naskh Arabic';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/NotoNaskhArabic.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Noto Naskh Arabic';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}/NotoNaskhArabic.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Noto Naskh Arabic';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-path}/NotoNaskhArabic.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Noto Naskh Arabic';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}/NotoNaskhArabic.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// /* arabic */
// @font-face {
//   font-family: 'Noto Naskh Arabic';
//   font-style: normal;
//   font-weight: 400;
//   src: url(''#{$font-path}/NotoNaskhArabic.woff2'') format('woff2'),
//   url('#{$font-path}/NotoNaskhArabic.woff') format('woff'),
//   url('#{$font-path}/NotoNaskhArabic.ttf') format('truetype');
//   unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* arabic */
// @font-face {
//   font-family: 'Noto Naskh Arabic';
//   font-style: normal;
//   font-weight: 500;
//   src: url(''#{$font-path}/NotoNaskhArabic.woff2'') format('woff2'),
//   url('#{$font-path}/NotoNaskhArabic.woff') format('woff'),
//   url('#{$font-path}/NotoNaskhArabic.ttf') format('truetype');
//   unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* arabic */
// @font-face {
//   font-family: 'Noto Naskh Arabic';
//   font-style: normal;
//   font-weight: 600;
//   src: url(''#{$font-path}/NotoNaskhArabic.woff2'') format('woff2'),
//   url('#{$font-path}/NotoNaskhArabic.woff') format('woff'),
//   url('#{$font-path}/NotoNaskhArabic.ttf') format('truetype');
//   unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* arabic */
// @font-face {
//   font-family: 'Noto Naskh Arabic';
//   font-style: normal;
//   font-weight: 700;
//   src: url(''#{$font-path}/NotoNaskhArabic.woff2'') format('woff2'),
//   url('#{$font-path}/NotoNaskhArabic.woff') format('woff'),
//   url('#{$font-path}/NotoNaskhArabic.ttf') format('truetype');
//   unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }


/*@font-face {
  font-family: 'IRANSansWeb';
  src: url('#{$font-path}/IRANSansWebFaNum.woff2') format('woff2'),
  url('#{$font-path}/IRANSansWebFaNum.woff') format('woff'),
  url('#{$font-path}/IRANSansWebFaNum.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yekan';
  src: url('#{$font-path}/YekanWeb-Regular.woff2') format('woff2'),
  url('#{$font-path}/YekanWeb-Regular.woff') format('woff'),
  url('#{$font-path}/YekanWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yekan Numbers';
  src: url('#{$font-path}/YekanNumbers-Regular.woff2') format('woff2'),
  url('#{$font-path}/YekanNumbers-Regular.woff') format('woff'),
  url('#{$font-path}/YekanNumbers-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

/*@font-face {
  font-family: 'Yekan Numbers';
  src: url('#{$font-path}/YekanNumbers-Regular.woff2') format('woff2'),
  url('#{$font-path}/YekanNumbers-Regular.woff') format('woff'),
  url('#{$font-path}/YekanNumbers-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'YasBold';
  src: url('#{$font-path}/YasBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'YasRegular';
  src: url('#{$font-path}/YasRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

/*@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('#{$font-path}/NotoSansArabic-Regular.woff2') format('woff2'),
  url('#{$font-path}/NotoSansArabic-Regular.woff') format('woff'),
  url('#{$font-path}/NotoSansArabic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

