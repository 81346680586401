$font-primary: "Vazir", Tahoma;
$font-secondary: "Vazir" , Tahoma;
$global-text-direction: rtl;
$header-font-family: $font-secondary;
$header-lineheight: 1.4;
$header-font-weight: 700;
$paragraph-font-size: 1.25rem;

/*
$header-styles: (
        small: (
                'h1': ('font-size': 36),
                'h2': ('font-size': 30),
                'h3': ('font-size': 26),
                'h4': ('font-size': 21),
                'h5': ('font-size': 19),
                'h6': ('font-size': 17),
        ),
        large: (
                'h1': ('font-size': 46),
                'h2': ('font-size': 38),
                'h3': ('font-size': 32),
                'h4': ('font-size': 26),
                'h5': ('font-size': 21),
                'h6': ('font-size': 19),
        ),
);
*/
