@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?s4dsoynxzz') format('woff2'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?s4dsoynxzz') format('truetype'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?s4dsoynxzz') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?s4dsoynxzz##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icomoon-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-email {
  &:before {
    content: $icon-email;     
    color: #6d6d6d;
  }
}
.icon-fb {
  &:before {
    content: $icon-fb;     
    color: #fff;
  }
}
.icon-home-2 {
  &:before {
    content: $icon-home-2; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-whatsapp2 {
  &:before {
    content: $icon-whatsapp2;     
    color: #6d6d6d;
  }
}
.icon-x {
  &:before {
    content: $icon-x;     
    color: #fff;
  }
}
.icon-twitter-x {
  &:before {
    content: $icon-twitter-x; 
  }
}
.icon-printer1 {
  &:before {
    content: $icon-printer1; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-instagram1 {
  &:before {
    content: $icon-instagram1; 
  }
}
.icon-snapchat-ghost {
  &:before {
    content: $icon-snapchat-ghost; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-sphere {
  &:before {
    content: $icon-sphere; 
  }
}
.icon-telegram {
  &:before {
    content: $icon-telegram; 
  }
}
.icon-printer {
  &:before {
    content: $icon-printer; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-whatsapp1 {
  &:before {
    content: $icon-whatsapp1; 
  }
}
.icon-dislike {
  &:before {
    content: $icon-dislike; 
  }
}
.icon-like {
  &:before {
    content: $icon-like; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-comment {
  &:before {
    content: $icon-comment; 
  }
}
.icon-uk-flag {
  &:before {
    content: $icon-uk-flag; 
  }
}
.icon-us-flag {
  &:before {
    content: $icon-us-flag; 
  }
}
.icon-google {
  &:before {
    content: $icon-google; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-arrow-down2 {
  &:before {
    content: $icon-arrow-down2; 
  }
}
.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2; 
  }
}
.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2; 
  }
}
.icon-arrow-up2 {
  &:before {
    content: $icon-arrow-up2; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-sound-wave {
  &:before {
    content: $icon-sound-wave; 
  }
}


