.menu-visible {
  background: $black;
  padding-top: 0 !important;
  .ad-block-header {
    @include breakpoint(large) {
      background-color: $black;
    }
  }
}

.menu-visible #main-header {
  position: relative;
  height: 100%;
}

.menu-visible #nav-panel {
  display: block;
}

.menu-visible .subheader-block ul li {
  &.edition,
  &.search {
    display: block;
  }
}

.menu-visible main,
.menu-visible .main-header-inner,
.menu-visible #main-footer {
  display: none;
}

.nav-panel-wrap {
  background-color: $black;
  color: $white;
  @include breakpoint(small only) {
    .menu-visible & {
      background-color: $white;
      color: $black;
    }
  }
}

#nav-panel {
  display: none;
  padding: 1rem;
  //background: #fff;
  @include breakpoint(large) {
    width: 1100px;
    margin: auto;
  }
  ul {
    list-style: none;
    margin: 0;
    font-family: $font-secondary;
    @include breakpoint(small only) {
      margin: 0 -1rem;
    }
    ul {
      @include breakpoint(medium down) {
        display: flex;
        flex-direction: column;
        padding: 0 35px;
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2;
        background-color: $smoke;
      }

      li {
        &:not(:last-child) {
          @include breakpoint(small only) { 
            border-bottom: 1px solid $medium-gray;
          }
          a {
            color: $black;
          }
        }
      }
    }
  }

  ul.topics {
    > li:not(.expanded) {
      border-bottom: 1px solid $black;
    }
    > li.expanded {
      max-height: 600px;
      padding-bottom: 20px;
      padding-top: 0;
      //background-color: $black;
      color: $white;
      > a {
        background-color: $black;
        color: $white;
        border-bottom: 1px solid $gray;
        &:hover {
          color: $primary-color;
        }

        .menu-visible & {
          color: $white;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    > li {
      > ul {
        li {
          position: relative;
          &:before { 
            position: absolute;
            font-family: "icomoon"; 
            top: 10px;
            color: $black;
            [dir="rtl"] & {
              left: 0;
              content: $icon-arrow-left2;
            }
            [dir="ltr"] & {
              right: 0;
              content: $icon-arrow-right2;
            }
          }
        }
        a {
          color: $white;
          display: block;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          padding: 6px 0;
          [dir="rtl"] & {
            font-weight: 400;
          }
          @include breakpoint(small only) {
            padding: 13px 0;
            color: $black;
          }
          @include breakpoint(large) {
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
}

@include breakpoint(small) {
  #nav-panel form.search {
    display: none;
  }
}

@include breakpoint(large) {
  #nav-panel .topics .toggle {
    display: none;
  }
}

#nav-panel .topics .expanded .toggle span {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#nav-panel ul.topics > li {
  max-height: 40px;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid $white;
  .toggle {
    content: "\e907";
    position: absolute;
    height: 100%;
    top: 0;
    #{$opposite-direction}: 2px;
    width: 40%;
    text-align: $opposite-direction;
    cursor: pointer;
    z-index: 1;
    padding-right: 20px;
    padding-left: 20px;
    i {
      font-size: 1rem;
      line-height: 40px;
    }
    &:hover {
      color: $primary-color;
    }
  }
}

#nav-panel ul.topics > li > a {
  color: $white;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 20px;
  text-transform: uppercase;
  [dir="rtl"] & {
    font-weight: 800;
  }
  @include breakpoint(large) {
    font-size: 13px;
    padding: 9px 0;
  }
  .menu-visible & {
    @include breakpoint(small only) {
      color: $black;
    }
  }
}

#nav-panel ul.topics > li > a:hover {
  color: #ec1a2e;
}

/*@media only screen and (max-width: 619px) {
  #nav-panel ul.topics > li > ul {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
  }
}

@media only screen and (min-width: 620px) and (max-width: 929px) {
  #nav-panel ul.topics > li > ul {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
  }
}*/

#nav-panel ul.topics > li > ul a:hover {
  color: #ec1a2e;
}

#nav-panel .socials-area {
  font-size: 18px;
  line-height: 22px;
  padding: 13px 0;
}

#nav-panel .socials-area li {
  display: inline-block;
  margin-#{$opposite-direction}: 5px;
  margin-bottom: 10px;
}

#nav-panel .socials-area {
  h6 {
    margin-bottom: 0;
    margin-#{$opposite-direction}: 10px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1.125rem;
  }
  .socials-btns {
    display: inline-block;
    vertical-align: middle;
  }
}

#nav-panel .socials-area a:hover {
  background: #ec1a2e;
}

#nav-panel .secButtons {
  list-style: none;
  margin-top: 15px;
  text-align: $default-direction;
}

#nav-panel .secButtons li {
  display: inline-block;
  margin-#{$opposite-direction}: 5px;
  margin-bottom: 10px;
}

.subscribe-field {
  display: none;
  @include breakpoint(630px down) {
    display: block;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: $font-secondary;
    strong {
      font-weight: 700;
    }
    a {
      color: $secondary-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

@include breakpoint(large) {
  #nav-panel > ul:after,
  #nav-panel > ul:before {
    content: "";
    display: table;
  }

  #nav-panel > ul:after {
    clear: both;
  }

  #nav-panel .socials-area {
    border-top: 1px solid $white;
    color: #000;
  }

  #nav-panel .secButtons {
    text-align: $opposite-direction;
  }

  #nav-panel ul.topics > li {
    max-height: none;
    padding-bottom: 20px;
    float: $default-direction;
    width: 154px;
    margin: 0 20px;
    border: none;
  }

  #nav-panel ul.topics > li > a {
    border-bottom: 1px solid $white;
    margin-bottom: 10px;
  }

  #nav-panel ul.topics > li:nth-child(6) {
    clear: $default-direction;
  }
}
