.main-header {
    &-inner {
        @include breakpoint(small only) {
            position: relative;
            height: 40px;
        }
    }
    &__navbar {
        @include breakpoint(small only) {
            display: flex;
            justify-content: center;
            gap: 30px;
            align-items: center;
        }
        .nav-container {
            @include breakpoint(small only) {
                position: absolute;
                top: 0;
                left: 0;
                padding: 0 15px;
                width: 100%;
                overflow-x: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }

            > .menu {
                @include breakpoint(small only) {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    gap: 50px;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    li {
                        display: block;
                        position: relative;
                    }
                } 
            }
        }
    }
}
