.nav-container {
  > ul {
    margin: 0;
    font-family: $font-secondary;
    font-size: 13px;
    font-weight: 500;
    [dir='rtl'] & {
      font-family: $font-secondary;
      font-size: 15px;
      font-weight: 800;
    }
    .urdu-theme & {
      font-size: 21px;
    }
    > li {
      &.active {
      > a {
        &:hover {
          color: white;
          }
        }
      }
      &:hover {
        > a {
          &:after {
            display: block;
          }
        }
        > ul {
          display: flex;
          visibility: visible;
          opacity: 1;
          -webkit-transition-delay: 0s;
          -moz-transition-delay: 0s;
          -ms-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s;
        }
      }
      &.active {
        > a {
          &:after {
            display: block;
          }
        }
      }
      > a {
        padding: 11px 8px 11px 16px;
        text-transform: uppercase;
        color: $secondary-color;
        display: block;
        white-space: nowrap;
        position: relative;
        [dir="rtl"] & {
          padding: 11px 16px 11px 8px;
        }
        &:after {
          content: " ";
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: $primary-color;
          display: none;
        }
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  > ul {
    ul {
      margin: 0;
      //display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100vw;
      list-style: none;
      background-color: $black;
      z-index: 200;
      opacity: 0;
      visibility: hidden;
      transition: .5s all;   
      transition-delay: 1s; 
      li {
        a {
          display: block;
          padding: 16px 22px;
          font-size: 14px;
          color: #fff;
          line-height: 16px;
          position: relative;
          &:after {
            content: " ";
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: $primary-color;
            display: none;
          }
          &:hover {
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }
}
